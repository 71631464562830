import React from "react";
import { IEvent, ILink, IQuestionDto } from "../../Core/types";

import "../Player.css";

import List from "@material-ui/core/List";
import Link, { LinkProps } from "@material-ui/core/Link";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

interface PlayerExtrasProps {
  event: IEvent;
  links: ILink[];
  isMulticastPlayer?: Boolean;
  submitQuestion: (question: IQuestionDto) => void;
}

const ListItemLink = (
  props: ListItemProps<"a", { button?: true }> & LinkProps
) => {
  return (
    <ListItem
      button
      component={Link}
      rel="noreferrer"
      style={{ color: "webkit-link" }}
      target="_blank"
      {...props}
    />
  );
};

function getHeight(url : string) {
  let height = "500";
  if (url.includes("?height=")) {
    const tuple = url.split("?height=");
    height = tuple[1];
  }

  return `${height}px`;
}

const PlayerExtras: React.FC<PlayerExtrasProps> = (
  props
): React.ReactElement => {
  const [questionForm, setQuestionForm] = React.useState<IQuestionDto>({
    Email: "",
    Name: "",
    Question: "",
    EventID: props.event.EventID,
  });

  const slido = props.links.find((l) => l.URL.startsWith("https://app.sli.do"));

  let filteredLinks;
  if (slido) {
    filteredLinks = props.links.filter(
      (l) => !l.URL.startsWith("https://app.sli.do")
    );
  } else {
    filteredLinks = props.links;
  }

  const handleSubmit = () => {
    if (
      questionForm.Email &&
      questionForm.Email.trim() &&
      questionForm.Name &&
      questionForm.Name.trim() &&
      questionForm.Question &&
      questionForm.Question.trim()
    ) {
      props.submitQuestion(questionForm);
      setQuestionForm({
        Email: "",
        Name: "",
        Question: "",
        EventID: props.event.EventID,
      });
    }
  };

  const [displayQuestions] = React.useState(!!props.event.IsQuestions);

  const handleQuestionFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuestionForm({ ...questionForm, [e.target.name]: e.target.value });
  };

  const eventDate = new Date(props.event.DateOfEvent || Date.now());
  return (
    <div className="player--extras__container">
      {!props.isMulticastPlayer && (
        <>
          <div className="player--extras__info">
            <Typography variant="h6" component="p">
              {props.event.Title}
            </Typography>
            <Typography variant="subtitle1" component="p">
              {props.event.Subtitle}
            </Typography>
            <Typography variant="subtitle1" component="p">
              {eventDate.toLocaleDateString(navigator.language)} @{" "}
              {eventDate.toLocaleTimeString(navigator.language, {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </div>

          <>
            {displayQuestions && (
              <>
                {!slido && (
                  <form className="player--extras__questions">
                    <Typography variant="h6" className="underlined">
                      Ask a Question
                    </Typography>
                    <div className="control">
                      <label
                        htmlFor="name-input"
                        style={{ marginRight: "16px" }}
                      >
                        Name:
                      </label>
                      <TextField
                        id="name-input"
                        name="Name"
                        size="small"
                        margin="dense"
                        variant="outlined"
                        className="form-control"
                        value={questionForm.Name}
                        onChange={handleQuestionFormChange}
                      />
                    </div>
                    <div className="control">
                      <label
                        htmlFor="email-input"
                        style={{ marginRight: "16px" }}
                      >
                        Email:
                      </label>
                      <TextField
                        id="email-input"
                        name="Email"
                        size="small"
                        margin="dense"
                        variant="outlined"
                        className="form-control"
                        value={questionForm.Email}
                        onChange={handleQuestionFormChange}
                      />
                    </div>
                    Question:{" "}
                    <TextField
                      rows={4}
                      fullWidth
                      multiline
                      size="small"
                      margin="dense"
                      name="Question"
                      variant="outlined"
                      className="form-control"
                      value={questionForm.Question}
                      onChange={handleQuestionFormChange}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      className="submit-btn"
                      onClick={handleSubmit}
                    >
                      Submit Question
                    </Button>
                  </form>
                )}

                {slido && (
                  <div className="player--extras__slido">
                    <iframe style={{ minHeight: getHeight(slido.URL) }} title="Sli.do" src={slido.URL} />
                  </div>
                )}
              </>
            )}
          </>
        </>
      )}

      {!!filteredLinks && filteredLinks.length > 0 && (
        <div className="player--extras__links-list">
          <Typography className="underlined" variant="h6">
            Links
          </Typography>
          <List dense disablePadding>
            {props.links
              .filter((l) =>
                slido ? !l.URL.startsWith("https://app.sli.do") : true
              )
              .map((l, i) => (
                <ListItemLink key={`link_${i}_${l.Name}`} href={l.URL}>
                  <ListItemText primary={l.Name} />
                </ListItemLink>
              ))}
          </List>
        </div>
      )}

      <div className="player--extras__additional-info">
        <Typography className="underlined" variant="h6">
          {props.event.AdditionalTitle}
        </Typography>
        <Typography variant="body1">{props.event.AdditionalText}</Typography>
      </div>
    </div>
  );
};

export default PlayerExtras;
