export type IEvent = {
    EventID: number;
    Title: string | null;
    Subtitle: string | null;
    DateOfEvent: Date | null;
    AccessID: string | null;
    AccessPWD: string | null;
    IsActive: boolean;
    IsDeleted: boolean;
    AdditionalTitle: string | null;
    AdditionalText: string | null;
    CreatedDate: Date;
    CreatedBy: string | null;
    IsRebroadcast: boolean;
    IsTemplate: boolean;
    IsHidden: boolean;
    IsQuestions: boolean;
    IsFrame: boolean;
};

type IEventDtoFields =
    | "Title"
    | "Subtitle"
    | "DateOfEvent"
    | "AccessID"
    | "AccessPWD"
    | "AdditionalTitle"
    | "AdditionalText"
    | "IsQuestions"
    | "IsFrame";

export type IEventDto = Pick<IEvent, IEventDtoFields>;

export enum ConnectionType {
    UNICAST_REMOTE = "unicastRemote",
    UNICAST_WIRELESS = "unicastWireless",
    MULTICAST = "multicast",
}

export type IAttend = {
    AttendID: number;
    User: string | null;
    Host: string | null;
    Local: string | null;
    Remote: string | null;
    Browser: string | null;
    Version: string | null;
    BrowserType: string | null;
    Platform: string | null;
    IsMobile: boolean;
    ConnectionType: ConnectionType;
    MobileManufacturer: string | null;
    MobileModel: string | null;
    OS: string | null;
    Agent: string | null;
    CreatedDate: Date;
    EventID: number;
};

export enum EncoderOperation {
    STOPPED = 1,
    STARTED,
    ERROR,
}

export type IEncoder = {
    EncoderID: number;
    Name: string | null;
    URL: string | null;
    Username: string | null;
    Password: string | null;
    CreatedDate: Date;
    CreatedBy: string | null;
    Status: EncoderOperation;
};
export type IEncoderDto = Pick<
    IEncoder,
    "Name" | "URL" | "Username" | "Password"
>;

export type IFrame = {
    FrameID: number;
    Name: string;
    URL: string;
    IsDeleted: boolean;
    CreatedDate: Date;
    CreatedBy: string | null;
    EventID: number;
    IsActive: boolean;
};

export type IFrameDto = Pick<IFrame, "Name" | "EventID" | "URL">;

export type IpRange = {
    IPRangeID: number;
    Name: string;
    IPv4: string;
    Type: "multicast" | "unicastWireless" | "unicastRemote";
    CreatedDate: Date;
    CreatedBy: string | null;
};

export type IpRangeDto = Pick<IpRange, "Name" | "IPv4" | "Type">;

export type IQuestion = {
    QuestionID: number;
    Name: string | null;
    Email: string | null;
    Question: string | null;
    IsPresenter: boolean;
    IsAnswered: boolean;
    IsModerator: boolean;
    IsDeleted: boolean;
    CreatedDate: Date;
    CreatedBy: string | null;
    EventID: number;
};

export type IQuestionDto = Pick<
    IQuestion,
    "Name" | "Question" | "Email" | "EventID"
>;

export type ILink = {
    LinkID: number;
    Name: string;
    URL: string;
    IsDeleted: boolean;
    CreatedBy: string | null;
    CreatedDate: Date;
    EventID: number;
};

export type ILinkDto = Pick<ILink, "EventID" | "URL" | "Name">;

export type ISource = {
    SourceID: number;
    EventID: number;
    PrimaryMulticastUrl: string;
    FailoverMulticastUrl: string | null;
    PrimaryRTMPSource: string | null;
    FailoverRTMPSource: string;
    PrimaryUnicastUrl: string | null;
    SecondaryUnicastUrl: string | null;
    ReplayUrl: string | null;
    CreatedDate: Date;
    CreatedBy: string | null;
};
export type ISourceDto = Pick<
    ISource,
    | "PrimaryMulticastUrl"
    | "FailoverMulticastUrl"
    | "PrimaryRTMPSource"
    | "FailoverRTMPSource"
    | "PrimaryUnicastUrl"
    | "SecondaryUnicastUrl"
    | "ReplayUrl"
    | "EventID"
>;

export type Credentials = {
    AccessID: string;
    AccessPWD: string;
};

export type ILiveEvent = IEvent & {
    Source: ISource;
    Frames: IFrame[];
    Links: ILink[];
};

export type IEncoderSession = {
    EncoderSessionID: number;
    EventID: number;
    EncoderID: number;
    Status: EncoderOperation;
    Message: string | null;
    Input: string;
    Output: string;
    CreatedDate: Date;
    CreatedBy: string | null;
};


export type EncoderStatusResponse = {
    meta: { [key: string]: number | string };
    broadcasting: boolean;
    sha?: string;
    version?: string;
    output?: string;
};

export interface EncoderResponse {
    status: string;
    Status: EncoderOperation;
    descriptive: string;
    output?: string;
}