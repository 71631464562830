import React from "react";
import "../IpRanges.css";
import { IpRange } from "../../Core/types";

import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

import IpRangeEdit from "./IpRangeEdit";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export const TYPE_MAP = {
    unicast: "Unicast",
    multicast: "Multicast",
    unicastWireless: "Unicast Wireless",
    unicastRemote: "Unicast Remote",
};

type Key = keyof typeof TYPE_MAP;

interface IpRangesListProps {
    ipRanges: IpRange[];
    deleteIpRange: (id: number) => void;
    submit: (ipRange: IpRange) => void;
}

const IpRangesList: React.FC<IpRangesListProps> = ({
    deleteIpRange,
    ipRanges,
    submit,
}): React.ReactElement => {
    const [selectedRange, setSelectedRange] = React.useState<IpRange | null>(
        null
    );

    const onSubmitEdit = (range: IpRange) => {
        setSelectedRange(null);
        submit(range);
    };

    const handleCancel = () => {
        setSelectedRange(null);
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow className="table__head">
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Ip Range</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ipRanges.map((ipRange, index) => {
                    const bg = index % 2 === 0 ? "#f6f5f7" : "inherit";
                    return selectedRange &&
                        ipRange.IPv4 === selectedRange.IPv4 ? (
                        <IpRangeEdit
                            key={`${ipRange.IPv4}_${index}__edit`}
                            backgroundColor={bg}
                            cancel={handleCancel}
                            ipRange={selectedRange}
                            onSubmitEdit={onSubmitEdit}
                        />
                    ) : (
                        <TableRow
                            style={{ backgroundColor: bg }}
                            key={`${ipRange.IPv4}_${index}`}
                        >
                            <TableCell align="center">
                                {ipRange.IPRangeID}
                            </TableCell>
                            <TableCell align="center">{ipRange.Name}</TableCell>
                            <TableCell align="center">{ipRange.IPv4}</TableCell>
                            <TableCell align="center">
                                {TYPE_MAP[ipRange.Type as Key]}
                            </TableCell>
                            <TableCell
                                className="white-space-nowrap"
                                align="right"
                            >
                                <Tooltip title="Edit">
                                    <IconButton
                                        size="small"
                                        style={{ marginRight: 12 }}
                                        onClick={() =>
                                            setSelectedRange(ipRange)
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            deleteIpRange(ipRange.IPRangeID)
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default IpRangesList;
