import React from "react";
import { ILiveEvent, IQuestionDto } from "../../Core/types";
import PlayerExtras from "./PlayerExtras";

import * as EventService from "../../Core/Services/EventService";
import * as QuestionService from "../../Core/Services/QuestionService";
import getUsername from "../../Core/Hooks/useUsername";

const Widget = () => {
    const [event, setEvent] = React.useState<ILiveEvent | null>(null);
    const submitQuestion = (question: IQuestionDto) => {
        QuestionService.createQuestion(question).catch((err) =>
            console.log("[QUESTION/API] Create Question Failed", err.message)
        );
    };

    const username = getUsername();
    React.useEffect(() => {
        EventService.getLiveEvent(username)
            .then((data) => setEvent(data?.event || null))
            .catch((err) =>
                console.log("[EVENT/API] Get live event Failed", err.message)
            );
    }, [setEvent, username]);
    return (
        <div style={{ width: "calc(300px - 16px)", padding: "8px" }}>
            {event ? (
                <PlayerExtras
                    links={event.Links || []}
                    event={event}
                    submitQuestion={submitQuestion}
                />
            ) : (
                <p>There is no active event.</p>
            )}
        </div>
    );
};

export default Widget;
