import React from "react";
import { IEvent } from "../Core/types";
import { NavLink } from "react-router-dom";

import Radio from "@material-ui/core/Radio";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
interface RowProps {
    backgroundColor: string;
    event: IEvent;
    isEventTable: boolean;
    isReplayTable: boolean;
    isTemplateTable: boolean;
    deleteEvent: (event: IEvent) => void;
    duplicateEvent: (event: IEvent) => void;
    markEventAsLive: (event: IEvent) => void;
    selectEventToEdit: (event: IEvent) => void;
    markEventAsRebroadcast: (event: IEvent) => void;
}

const Row: React.FC<RowProps> = (props): React.ReactElement => {
    return (
        <TableRow style={{ backgroundColor: props.backgroundColor }}>
            <TableCell align="center">{props.event.EventID}</TableCell>
            <TableCell align="center" padding="default">
                <NavLink to={`/admintools/events/${props.event.EventID}`}>
                    {props.event.Title}
                </NavLink>

                <p className="p-0 m-0">{props.event.Subtitle}</p>
                {props.isReplayTable && (
                    <small style={{ color: "#DC3942" }} className="link">
                        https://video.thehartford.com/?Replay=
                        {props.event.EventID}
                    </small>
                )}
                <div className="p-0 m-0 white-space-nowrap">
                    Template:{" "}
                    {props.event.IsTemplate ? (
                        <div className="check-icon">
                            <CheckIcon />
                        </div>
                    ) : (
                        <Radio disabled />
                    )}
                </div>
            </TableCell>
            <TableCell align="center">
                {props.event.DateOfEvent
                    ? new Date(props.event.DateOfEvent).toLocaleString(
                          navigator.language,
                          {
                              hour12: true,
                              hour: "2-digit",
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              minute: "2-digit",
                          }
                      )
                    : "Not Set"}
            </TableCell>
            <TableCell align="center" padding="default">
                <p className="flex flex-row nowrap justify-content-between">
                    ID:&nbsp;
                    <span>{props.event.AccessID || " Not Set"}</span>
                </p>

                <p className="flex flex-row nowrap justify-content-between">
                    PWD:&nbsp;
                    <span>{props.event.AccessPWD || " Not Set"}</span>
                </p>
            </TableCell>
            <TableCell align="center" padding="default">
                <h3>{props.event.AdditionalTitle}</h3>
                <span style={{ overflowWrap: "break-word" }}>
                    {props.event.AdditionalText}
                </span>
            </TableCell>

            <TableCell align="center">
                <div className="radio justify-content-between">
                    Questions:{" "}
                    {props.event.IsQuestions ? (
                        <div className="check-icon">
                            <CheckIcon />
                        </div>
                    ) : (
                        <Radio disabled />
                    )}
                </div>

                <div className="radio justify-content-between">
                    Frame:{" "}
                    {props.event.IsFrame ? (
                        <div className="check-icon">
                            <CheckIcon />
                        </div>
                    ) : (
                        <Radio disabled />
                    )}
                </div>
            </TableCell>
            <TableCell align="center">
                {!!props.event.IsTemplate || !!props.event.IsRebroadcast ? (
                    <Radio disabled />
                ) : !!props.event.IsActive ? (
                    <div className="check-icon">
                        <CheckIcon />
                    </div>
                ) : (
                    <Radio
                        checked={!!props.event.IsActive}
                        onClick={() => props.markEventAsLive(props.event)}
                    />
                )}
            </TableCell>
            <TableCell align="center">
                {!!props.event.IsTemplate ? (
                    <Radio disabled />
                ) : !!props.event.IsRebroadcast ? (
                    <div className="check-icon">
                        <CheckIcon />
                    </div>
                ) : (
                    <Radio
                        checked={!!props.event.IsRebroadcast}
                        onClick={() =>
                            props.markEventAsRebroadcast(props.event)
                        }
                    />
                )}
            </TableCell>
            <TableCell
                align="center"
                padding="default"
                className="white-space-nowrap"
            >
                <Tooltip title="Edit">
                    <IconButton
                        size="small"
                        style={{ marginRight: 12 }}
                        onClick={() => props.selectEventToEdit(props.event)}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Duplicate">
                    <IconButton
                        size="small"
                        style={{ marginRight: 12 }}
                        onClick={() => props.duplicateEvent(props.event)}
                    >
                        <FileCopyIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        size="small"
                        onClick={() => props.deleteEvent(props.event)}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default Row;
