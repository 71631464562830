import axios from "axios";
import { IpRangeDto, IpRange } from "../types";

export const createIpRange = (ipRange: IpRangeDto): Promise<IpRange> => {
    return axios
        .post<{ data: IpRange }>("/api/ipranges", ipRange)
        .then((r) => r.data.data);
};

export const updateIpRange = (ipRange: IpRange): Promise<IpRange> => {
    return axios
        .put<{ data: IpRange }>(`/api/ipranges/${ipRange.IPRangeID}`, ipRange)
        .then((r) => r.data.data);
};

export const deleteIpRange = (id: number): Promise<IpRange> => {
    return axios
        .delete<{ data: IpRange }>(`/api/ipranges/${id}`)
        .then((r) => r.data.data);
};

export const getIpRanges = (): Promise<IpRange[]> => {
    return axios
        .get<{ data: IpRange[] }>("/api/ipranges")
        .then((r) => r.data.data);
};

export const testIp = (
    ip: string | undefined
): Promise<{ full: string; type: string; name: string }> => {
    return axios
        .post<{
            data: { result: { full: string; type: string; name: string } };
        }>("/api/ipranges/test", { testIp: ip })
        .then((r) => {
            return r.data.data.result;
        });
};
