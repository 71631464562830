import { Typography } from "@material-ui/core";
import React from "react";
import "./Footer.css";

const Footer: React.FC = (): React.ReactElement => {
  
    return (
        <div className="footerContainer">
            <Typography variant="caption" component="p">
                &copy; 2023 The Hartford
            </Typography>
        </div>
    );
};

export default Footer;
