import React from "react";
import { IEvent } from "../../Core/types";

import WarningDialog from "../../Shared/Dialogs/CustomDialog";

import * as EventService from "../../Core/Services/EventService";
import EventTable from "../../Shared/EventTable";
type DialogState = {
    handleClose: (() => void) | ((yes: boolean) => void);
    type: "confirm" | "info";
    title: string;
};

const ReplaysContainer: React.FC = (): React.ReactElement => {
    const [events, setEvents] = React.useState<IEvent[]>([]);
    const [dialogState, setDialogState] = React.useState<DialogState | false>(
        false
    );
    React.useEffect(() => {
        EventService.getRebroadcastEvents()
            .then(setEvents)
            .catch((err) => {
                console.error(
                    "[REPLAY/API] Get Rebroadcast Events Failed",
                    err.message
                );
            });
    }, [setEvents]);

    const handleUpdateEvent = (
        event: IEvent
    ): Promise<{ errors: any } | null> => {
        if (event.IsTemplate) {
            event.IsRebroadcast = false;
        }
        return EventService.updateEvent(event)
            .then((updatedEvent) => {
                if (!updatedEvent.IsRebroadcast) {
                    setEvents(
                        events.filter((e) => e.EventID !== updatedEvent.EventID)
                    );
                } else {
                    setEvents(
                        events.map((e) =>
                            e.EventID === updatedEvent.EventID
                                ? updatedEvent
                                : e
                        )
                    );
                }
                return null;
            })
            .catch((err) => {
                console.warn(
                    "[REPLAY/API] Update Rebroadcast Event Failed",
                    err
                );
                return { errors: err };
            });
    };

    const handleDuplicate = (event: IEvent) => {
        EventService.duplicateEvent(event)
            .then((newEvent) => setEvents([newEvent, ...events]))
            .catch((err) => {
                console.warn(
                    "[REPLAY/API] Duplicate Rebroadcast Event Failed",
                    err
                );
            });
    };

    const deleteEvent = (id: number) => {
        setDialogState(false);
        EventService.deleteEvent(id)
            .then(() => setEvents(events.filter((e) => e.EventID !== id)))
            .catch((err) =>
                console.error("[ERROR] Event couldn't be deleted", err.message)
            );
    };

    const handleDelete = (event: IEvent) => {
        if (events.length <= 1) {
            setDialogState({
                type: "info",
                handleClose: () => {
                    setDialogState(false);
                },
                title: "Cannot delete last event",
            });
        } else {
            setDialogState({
                handleClose: (confirmation: boolean) => {
                    if (confirmation) {
                        deleteEvent(event.EventID);
                    }
                    setDialogState(false);
                },
                title: "Delete Event?",
                type: "confirm",
            });
        }
    };

    const handleMarkActive = (_event: IEvent) => {};
    const handleMarkAsTemplate = (_event: IEvent) => {};
    const handleMarkRebroadcast = (_event: IEvent) => {};

    return (
        <>
            {dialogState && <WarningDialog {...dialogState} />}
            <EventTable
                events={events}
                isEventTable={false}
                isReplayTable={true}
                isTemplateTable={false}
                onDelete={handleDelete}
                onDuplicate={handleDuplicate}
                onMarkActive={handleMarkActive}
                onEditSubmit={handleUpdateEvent}
                onMarkAsTemplate={handleMarkAsTemplate}
                onMarkRebroadcast={handleMarkRebroadcast}
            />
        </>
    );
};

export default ReplaysContainer;
