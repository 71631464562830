import React from "react";
import "./VideoEncoder.css";
import { EncoderOperation, IEncoder } from "../../Core/types";

import Menu from "@material-ui/core/Menu";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import useInterval from "../../Core/Hooks/useInterval";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PlayArrow from "@material-ui/icons/PlayCircleFilledOutlined";

import * as EncoderService from "../../Core/Services/EncoderService";
import { AxiosError } from "axios";

interface VideoEncoderRowProps {
    backgroundColor: string;
    selectEncoder: (encoder: IEncoder, status: EncoderOperation) => void;
    setErrorMessage: (str: string) => void;
    deleteEncoder: (id: number) => void;
    toggleEncoder: (encoder: IEncoder, status: string) => void;
    testEncoder: (encoder: IEncoder) => void;
    encoder: IEncoder;
}

const VideoEncoderRow: React.FC<VideoEncoderRowProps> = ({
    encoder,
    testEncoder,
    toggleEncoder,
    deleteEncoder,
    selectEncoder,
    setErrorMessage,
    backgroundColor,
}): React.ReactElement => {
    const [status, setStatus] = React.useState<string>("Checking...");
    const [
        rawEncoderStatus,
        setRawEncoderStatus,
    ] = React.useState<EncoderOperation>(3);
    const encoderIdentifier = `Encoder: ${encoder.EncoderID} [${encoder.URL}]`;
    const [
        extraEncoderInformation,
        setExtraEncoderInformation,
    ] = React.useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBlurClose = () => {
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
        toggleEncoder(encoder, status);
    };
    const getStatus = React.useCallback(() => {
        EncoderService.getStatus(encoder)
            .then((response) => {
                const { status, Status, descriptive } = response;
                setStatus(status);
                setRawEncoderStatus(Status);
                setExtraEncoderInformation(descriptive);
                if (status.includes("Error")) {
                    setErrorMessage(descriptive);
                }
            })
            .catch((err) => {
                // We should not see this, move into the getStatus call.
                setStatus("Error");
                setRawEncoderStatus(EncoderOperation.ERROR);
                try {
                    setExtraEncoderInformation(
                        (err as AxiosError).response!.data.message
                    );
                } catch (err) {
                    console.error('Error setting extra encoder information', err);
                }
                setErrorMessage(
                    `${encoderIdentifier}: ${(err as AxiosError).response!.data.message
                    }`
                );
            });
    }, [encoder, encoderIdentifier, setErrorMessage]);

    useInterval(async () => {
        getStatus();
    }, 5 * 1000);

    React.useEffect(() => {
        if (encoder.URL) {
            getStatus();
        }
    }, [encoder, getStatus]);

    return (
        <>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleBlurClose}
            >
                <MenuItem onClick={handleClose}>
                    {status === "Started" ? "Stop" : "Start"}
                </MenuItem>
            </Menu>
            <TableRow
                className={status === "Error" ? "pulse-red" : ""}
                style={{ backgroundColor }}
            >
                <TableCell align="center">{encoder.EncoderID}</TableCell>
                <TableCell align="center">{encoder.Name}</TableCell>
                <TableCell align="center">{encoder.URL}</TableCell>
                <TableCell align="center">{encoder.Username}</TableCell>
                <TableCell align="center">{encoder.Password}</TableCell>
                <TableCell align="center">
                    <Tooltip
                        title={extraEncoderInformation}
                        arrow
                        color="error"
                    >
                        <Typography
                            style={{
                                color:
                                    status === "Started"
                                        ? "green"
                                        : status === "Stopped"
                                            ? "purple"
                                            : "red",
                            }}
                        >
                            {status}

                        </Typography>
                    </Tooltip>
                    {status === "Started" && (
                        <IconButton onClick={() => testEncoder(encoder)} color="primary" size="small">
                            <PlayArrow />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell className="white-space-nowrap" align="right">
                    <IconButton
                        size="small"
                        style={{ marginRight: 12 }}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Tooltip title="Edit">
                        <IconButton
                            size="small"
                            style={{ marginRight: 12 }}
                            onClick={() =>
                                selectEncoder(encoder, rawEncoderStatus)
                            }
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            size="small"
                            onClick={() => deleteEncoder(encoder.EncoderID)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    );
};

export default VideoEncoderRow;
