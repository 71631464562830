import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
    },
}));

interface CustomDialogProps {
    title: string;
    type: "confirm" | "info";
    handleClose: (confirmation: boolean) => void;
}

const CustomDialog: React.FC<CustomDialogProps> = (
    props
): React.ReactElement => {
    const classes = useStyles();

    return (
        <Dialog
            open={true}
            onClose={() => props.handleClose(false)}
            aria-labelledby={`${
                props.type === "confirm" ? "confirmation" : "alert"
            }-dialog-title`}
            aria-describedby={`${
                props.type === "confirm" ? "confirmation" : "alert"
            }-dialog-description`}
        >
            <DialogTitle
                id={`${
                    props.type === "confirm" ? "confirmation" : "alert"
                }-dialog-title`}
            >
                {props.title}
            </DialogTitle>

            <DialogActions>
                {props.type === "confirm" && (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => props.handleClose(true)}
                            classes={{ root: classes.root }}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => props.handleClose(false)}
                        >
                            No
                        </Button>
                    </>
                )}
                {props.type === "info" && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.handleClose(false)}
                    >
                        Ok
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
