import React from "react";
import EventList from "../Events/Components/EventList/EventList";
import { Route, Switch, useRouteMatch } from "react-router";
import EventDetails from "../Events/Components/EventDetails";
import AttendanceList from "../Events/Components/Attendance/AttendanceList";
import VideoStreamForm from "../Events/Components/VideoStreams/VideoStreamForm";
import QuestionList from "../Events/Components/Questions/QuestionList";
import FrameContainer from "../Events/Components/Frames/FrameContainer";
import LinksContainer from "../Events/Components/Links/LinksContainer";

const EventRouter: React.FC = (): React.ReactElement => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <EventList />
            </Route>
            <Route exact path={`${path}/:id`}>
                <EventDetails />
            </Route>
            <Route exact path={`${path}/:id/frames`}>
                <FrameContainer />
            </Route>
            <Route exact path={`${path}/:id/links`}>
                <LinksContainer />
            </Route>
            <Route exact path={`${path}/:id/links/slido`}>
                <LinksContainer slido={true} />
            </Route>
            <Route exact path={`${path}/:id/questions`}>
                <QuestionList />
            </Route>
            <Route exact path={`${path}/:id/video-streams`}>
                <VideoStreamForm />
            </Route>
            <Route exact path={`${path}/:id/attendance`}>
                <AttendanceList />
            </Route>
        </Switch>
    );
};

export default EventRouter;
