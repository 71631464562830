import React from "react";
import { EncoderOperation, IEncoder } from "../../Core/types";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import VideoEncoderRow from "./VideoEncoderRow";
import VideoEncoderEdit from "./VideoEncoderEdit";

interface VideoEncoderListProps {
    encoders: IEncoder[];
    setErrorMessage: (err: string) => void;
    deleteVideoEncoder: (id: number) => void;
    toggleEncoder: (encoder: IEncoder, status: string) => void;
    submit: (encoder: IEncoder) => void;
    handleTestEncoder: (encoder: IEncoder) => void;
}

const VideoEncoderList: React.FC<VideoEncoderListProps> = ({
    deleteVideoEncoder,
    setErrorMessage,
    encoders,
    submit,
    handleTestEncoder,
    toggleEncoder,
}): React.ReactElement => {
    const [selectedEncoder, setSelectedEncoder] = React.useState<{
        encoder: IEncoder;
        status: EncoderOperation;
    } | null>(null);

    const selectEditEncoder = (encoder: IEncoder, status: EncoderOperation) => {
        setSelectedEncoder({ encoder, status });
    };

    const onSubmitEdit = (encoder: IEncoder) => {
        setSelectedEncoder(null);
        submit(encoder);
    };
    const handleCancel = () => {
        setSelectedEncoder(null);
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow className="table__head">
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Video Encoder URL</TableCell>
                        <TableCell align="center">Username</TableCell>
                        <TableCell align="center">Password</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {encoders.map((encoder, i) => {
                        const bg = i % 2 === 0 ? "#f6f5f7" : "inherit";
                        return selectedEncoder &&
                            encoder.EncoderID ===
                                selectedEncoder.encoder.EncoderID ? (
                            <VideoEncoderEdit
                                cancel={handleCancel}
                                backgroundColor={bg}
                                key={`${encoder.EncoderID}_${i}__edit`}
                                status={selectedEncoder.status}
                                encoder={selectedEncoder.encoder}
                                onSubmitEdit={onSubmitEdit}
                            />
                        ) : (
                            <VideoEncoderRow
                                key={`${encoder.EncoderID}_${i}__row`}
                                setErrorMessage={setErrorMessage}
                                deleteEncoder={deleteVideoEncoder}
                                backgroundColor={bg}
                                testEncoder={handleTestEncoder}
                                toggleEncoder={toggleEncoder}
                                selectEncoder={selectEditEncoder}
                                encoder={encoder}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default VideoEncoderList;
