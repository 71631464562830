import axios from "axios";
import { ISourceDto, ISource } from "../types";

export const createSource = (source: ISourceDto): Promise<ISource> => {
    return axios
        .post<{ data: ISource }>("/api/sources", source)
        .then((response) => response.data.data);
};

export const updateSource = (source: ISource): Promise<ISource> => {
    return axios
        .put<{ data: ISource }>(`/api/sources/${source.SourceID}`, source)
        .then((r) => r.data.data);
};

export const deleteSource = (id: number): Promise<ISource> => {
    return axios
        .delete<{ data: ISource }>(`/api/sources/${id}`)
        .then((r) => r.data.data);
};

export const getSource = (eventId: number): Promise<ISource> => {
    return axios
        .get<{ data: ISource }>(`/api/sources/${eventId}`)
        .then((r) => r.data.data);
};
