import axios, { CancelToken } from "axios";
import { Credentials, IEvent, IEventDto, ILiveEvent } from "../types";
export const getEvents = (): Promise<IEvent[]> => {
    return axios
        .get<{ data: IEvent[] }>(`/api/events/`)
        .then((r) => r.data.data);
};
export const getEvent = (
    eventID: number,
    cancelToken?: CancelToken
): Promise<IEvent> => {
    return axios
        .get<{ data: IEvent }>(`/api/events/${eventID}`, { cancelToken })
        .then((r) => r.data.data);
};
export const getTemplateEvents = (): Promise<IEvent[]> => {
    return axios
        .get<{ data: IEvent[] }>(`/api/events/templates`)
        .then((r) => r.data.data);
};
export const getRebroadcastEvents = (): Promise<IEvent[]> => {
    return axios
        .get<{ data: IEvent[] }>(`/api/events/rebroadcast`)
        .then((r) => r.data.data);
};

export const createEvent = (event: IEventDto): Promise<IEvent> => {
    return axios
        .post<{ data: IEvent }>("/api/events", {
            ...event,
            CreatedBy: null,
            IsActive: false,
            IsDeleted: false,
            IsTemplate: true,
            IsRebroadcast: false,
        })
        .then((r) => r.data.data);
};

export const updateEvent = (event: IEvent): Promise<IEvent> => {
    return axios
        .put<{ data: IEvent }>(`/api/events/${event.EventID}`, event)
        .then((r) => r.data.data);
};

export const deleteEvent = (id: number): Promise<IEvent> => {
    return axios
        .delete<{ data: IEvent }>(`/api/events/${id}`)
        .then((r) => r.data.data);
};

export const duplicateEvent = (e: IEvent): Promise<IEvent> => {
    const { CreatedDate, ...event } = e;

    return axios
        .post<{ data: IEvent }>("/api/events", {
            ...event,
            Title: event.Title?.startsWith("(COPY)")
                ? event.Title
                : `(COPY) ${event.Title}`,
            DateOfEvent: new Date(),
        })
        .then((r) => r.data.data);
};

type LiveEventResponse = {
    type: "unicastWireless" | "unicastRemote" | "multicast";
    event: ILiveEvent;
    vivohProtocol: string | null;
    needsAuthentication: boolean;
} | null;

export const getLiveEvent = (
    eventId?: string | null
): Promise<LiveEventResponse> => {
    return axios
        .get<LiveEventResponse>(
            eventId && !isNaN(+eventId)
                ? `/api/events/replay/${eventId}`
                : "/api/events/live"
        )
        .then((r) => r.data);
};

export const loginToLiveEvent = (
    credentials: Credentials
): Promise<{ isAuthenticated: boolean }> => {
    return axios
        .post<{ isAuthenticated: boolean }>(
            `/api/events/authenticate`,
            credentials
        )
        .then((r) => r.data);
};
