import React from "react";
import { IEvent } from "../../Core/types";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
interface EncoderStartDialogProps {
    eventID: number;
    events: IEvent[];
    handleConfirmNo: () => void;
    handleConfirmEvent: () => void;
    setEventID: (id: number) => void;
}

const EncoderStartDialog: React.FC<EncoderStartDialogProps> = (
    props
): React.ReactElement => {
    return (
        <Dialog open={true} onClose={props.handleConfirmNo}>
            <DialogTitle>Choose an event</DialogTitle>
            <DialogContent>
                <FormControl
                    size="small"
                    margin="dense"
                    color="primary"
                    variant="outlined"
                    className="form-control"
                >
                    <InputLabel id="event_label">Event</InputLabel>

                    <Select
                        labelId="event_label"
                        id="event_id"
                        name="Event"
                        value={props.eventID || ""}
                        onChange={(e) =>
                            props.setEventID(e.target.value as number)
                        }
                        label="Event"
                    >
                        {props.events.map((e, i) => (
                            <MenuItem
                                key={`${e.EventID}_${i}`}
                                value={e.EventID}
                            >
                                {e.Title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={props.handleConfirmEvent}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EncoderStartDialog;
