import axios, { CancelToken } from "axios";
import { IFrame, IFrameDto } from "../types";

export const deleteFrame = (id: number): Promise<IFrame> => {
    return axios
        .delete<{ data: IFrame }>(`/api/frames/${id}`)
        .then((r) => r.data.data);
};

export const createFrame = (frame: IFrameDto): Promise<IFrame> => {
    return axios
        .post<{ data: IFrame }>("/api/frames", frame)
        .then((r) => r.data.data);
};

export const getFramesForEvent = (
    EventID: number,
    cancelToken?: CancelToken
): Promise<IFrame[]> => {
    return axios
        .get<{ data: IFrame[] }>(`/api/frames/${EventID}`, { cancelToken })
        .then((r) => r.data.data);
};

export const updateFrame = (frame: IFrame): Promise<IFrame> => {
    return axios
        .put<{ data: IFrame }>(`/api/frames/${frame.FrameID}`, frame)
        .then((r) => r.data.data);
};
