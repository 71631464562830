import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { IQuestion } from "../../../Core/types";

interface QuestionRowProps {
    question: IQuestion;
    deleteQuestion: (questionID: number) => void;
    handleChange: (q: IQuestion) => void;
}

const QuestionRow: React.FC<QuestionRowProps> = ({
    question,
    handleChange,
    deleteQuestion,
}): React.ReactElement => {
    return (
        <>
            <TableCell align="center">{question.QuestionID}</TableCell>

            <TableCell padding="default">{question.Name}</TableCell>
            <TableCell>{question.Email}</TableCell>
            <TableCell padding="default" style={{ wordBreak: "break-word" }}>
                {question.Question}
            </TableCell>

            <TableCell align="center">
                <Checkbox
                    checked={!!question.IsPresenter}
                    color="primary"
                    onClick={() => {
                        handleChange({
                            ...question,
                            IsPresenter: !question.IsPresenter,
                        });
                    }}
                />
            </TableCell>
            <TableCell align="center" padding="default">
                <Checkbox
                    checked={!!question.IsAnswered}
                    color="primary"
                    onClick={() => {
                        handleChange({
                            ...question,
                            IsAnswered: !question.IsAnswered,
                        });
                    }}
                />
            </TableCell>
            <TableCell padding="none">
                <IconButton onClick={() => deleteQuestion(question.QuestionID)}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </>
    );
};

export default QuestionRow;
