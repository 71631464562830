import React from "react";
import * as UserService from "../Services/UserService";

function useUsername() {
    const [username, setUsername] = React.useState<string>("");
    React.useEffect(() => {
        const getUsername = async () => {
            try {
                const u = await UserService.getUsername();
                await UserService.sendUsername(u);
                setUsername(u);
            } catch (err) {
                console.error("Could not fetch username");
                await UserService.sendUsername("No User");
            }
        };
        getUsername();
    }, []);

    return username;
}

export default useUsername;
