import React from "react";

const useInterval = (callback: () => void, delay: number): void => {
    const savedCallback = React.useRef<() => void>();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        const tick = () => {
            savedCallback.current!();
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
};

export default useInterval;
