import axios, { AxiosError } from "axios";

// type Config = {
//     sharepoint: boolean
//  };

export const getCfg = async () => {
    try {
        const response = await axios.get<string>("/cfg");
        if (response.data) {
            return response.data;
        }
    } catch (error: any) {
        console.log('Error in parsing config:', error.toString());
        return (error as AxiosError).response?.data;
    }
};

