/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import LinksForm from "./LinksForm";
import LinksList from "./LinksList";
import { useParams } from "react-router";
import { IEvent, ILink, ILinkDto } from "../../../Core/types";
import * as LinkService from "../../../Core/Services/LinkService";
import * as EventService from "../../../Core/Services/EventService";
import Header from "../../../Header/Header";

interface LinksContainerProps { slido?: Boolean }

const LinksContainer: React.FC<LinksContainerProps> = ({ slido }): React.ReactElement => {
    const [links, setLinks] = React.useState<ILink[]>([]);
    const [event, setEvent] = React.useState<IEvent | null>(null);
    const { id: eventID } = useParams<{ id: string }>();
    
    const handleSubmit = (link: ILinkDto) => {
        LinkService.createLink(link)
            .then((newLink) => setLinks([newLink, ...links]))
            .catch((err) => console.log("[LINKS/API] Create Link Failed", err.message));
    };

    function filterLinks(links: ILink[]) : ILink[] {
        if (slido) {
            return links
                .filter( l => l.URL.startsWith('https://app.sli.do'));
        } else {
            return links
                .filter( l => !l.URL.startsWith('https://app.sli.do'));
        }
    }

    const bottom = slido ? "Slido Link" : "Event Links";

    const handleDeleteLink = (id: number) => {
        LinkService.deleteLink(id)
            .then(() => setLinks(links.filter((l) => l.LinkID !== id)))
            .catch((err) => console.log("[LINKS/API] Delete Link Failed", err.message));
    };

    const handleEdit = (link: ILink) => {
        LinkService.updateLink(link)
            .then((updatedLink) =>
                setLinks(
                    links.map((l) =>
                        l.LinkID === updatedLink.LinkID ? updatedLink : l
                    )
                )
            )
            .catch((err) => console.log("[LINKS/API] Update Link Failed", err.message));
    };
    React.useEffect(() => {
        if (eventID) {
            LinkService.getLinks(+eventID)
                .then(links => filterLinks(links))
                .then(setLinks)
                .catch((err) =>
                    console.log("[LINKS/API] Get Links Failed", err.message)
                );
            EventService.getEvent(+eventID)
                .then(setEvent)
                .catch((err) =>
                    console.log("[EVENT/API] Get Event Failed", err.message)
                );
        }
    }, [setLinks, slido, eventID]);
    return (
        <>
            <Header top={event?.Title || ""} bottom={bottom} />
            <div className="main-content">
                <div className="table-and-form__wrapper">
                    <div className="table-and-form__table">
                        <LinksList
                            deleteLink={handleDeleteLink}
                            links={links}
                            submitLink={handleEdit}
                        />
                    </div>
                    <div className="table-and-form__form">
                        { ( slido && links.length > 0) &&
                            <>Only one Slido link per event is permitted</>
                        }
                        { (!slido || links.length < 1 ) && 
                            <LinksForm slido={slido} submit={handleSubmit} EventID={+eventID} />
                        }
                        </div>
                </div>
            </div>
        </>
    );
};

export default LinksContainer;
