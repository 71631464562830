import React from "react";
import { IpRange, IpRangeDto } from "../../Core/types";
import IpRangeForm from "../Components/IpRangeForm";
import IpRangesList from "../Components/IpRangesList";
import * as IpRangeService from "../../Core/Services/IpRangeService";

const IpRangesContainer: React.FC = (): React.ReactElement => {
    const [ipRanges, setIpRanges] = React.useState<IpRange[]>([]);

    React.useEffect(() => {
        IpRangeService.getIpRanges()
            .then(setIpRanges)
            .catch((err) =>
                console.log("[IPRANGE/API] Get IpRanges Failed", err.message)
            );
    }, [setIpRanges]);

    const handleSubmitEdit = (editedRange: IpRange) => {
        IpRangeService.updateIpRange(editedRange)
            .then((updatedRange) => {
                setIpRanges(
                    ipRanges.map((r) =>
                        r.IPRangeID === updatedRange.IPRangeID
                            ? updatedRange
                            : r
                    )
                );
            })
            .catch((err) =>
                console.log("[IPRANGE/API] Update IpRange Failed", err.message)
            );
    };

    const handleSubmitNew = (ipRange: IpRangeDto) => {
        IpRangeService.createIpRange(ipRange)
            .then((newIpRange) => setIpRanges([newIpRange, ...ipRanges]))
            .catch((err) =>
                console.log("[IPRANGE/API] Create IpRange Failed", err.message)
            );
    };

    const handleDeleteIpRange = (id: number) => {
        IpRangeService.deleteIpRange(id)
            .then(() => setIpRanges(ipRanges.filter((r) => r.IPRangeID !== id)))
            .catch((err) =>
                console.log("[IPRANGE/API] Delete IpRange Failed", err.message)
            );
    };

    return (
        <div className="table-and-form__wrapper">
            <div className="table-and-form__table">
                <IpRangesList
                    ipRanges={ipRanges}
                    submit={handleSubmitEdit}
                    deleteIpRange={handleDeleteIpRange}
                />
            </div>
            <div className="table-and-form__form width-300">
                <IpRangeForm submit={handleSubmitNew} />
            </div>
        </div>
    );
};

export default IpRangesContainer;
