import React from "react";
import "./Header.css";
import hartfordLogo from "./hartford.png";
import { getCfg } from "../Core/Services/ConfigService";

export type IHeader = {
  top: string;
  bottom: string;
};

const Header: React.FC<IHeader> = ({ top, bottom }): React.ReactElement => {
  const [cfg, setCfg] = React.useState<{ sharepoint: Boolean }>({
    sharepoint: false,
  });
  React.useEffect(() => {
    getCfg().then((config) => {
      if (config) {
        setCfg(config);
      }
    });
  }, []);

  return (
    <>
      <div className="headerContainer">
        {cfg && cfg["sharepoint"] && (
          <div className="sharepoint">
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
              <path d="M11.3 14.6Q9.9 14.6 8.95 13.65Q8 12.7 8 11.3Q8 9.9 8.95 8.95Q9.9 8 11.3 8Q12.7 8 13.65 8.95Q14.6 9.9 14.6 11.3Q14.6 12.7 13.65 13.65Q12.7 14.6 11.3 14.6ZM24 40Q22.6 40 21.65 39.05Q20.7 38.1 20.7 36.7Q20.7 35.3 21.65 34.35Q22.6 33.4 24 33.4Q25.4 33.4 26.35 34.35Q27.3 35.3 27.3 36.7Q27.3 38.1 26.35 39.05Q25.4 40 24 40ZM11.3 40Q9.9 40 8.95 39.05Q8 38.1 8 36.7Q8 35.3 8.95 34.35Q9.9 33.4 11.3 33.4Q12.7 33.4 13.65 34.35Q14.6 35.3 14.6 36.7Q14.6 38.1 13.65 39.05Q12.7 40 11.3 40ZM11.3 27.3Q9.9 27.3 8.95 26.35Q8 25.4 8 24Q8 22.6 8.95 21.65Q9.9 20.7 11.3 20.7Q12.7 20.7 13.65 21.65Q14.6 22.6 14.6 24Q14.6 25.4 13.65 26.35Q12.7 27.3 11.3 27.3ZM24 27.3Q22.6 27.3 21.65 26.35Q20.7 25.4 20.7 24Q20.7 22.6 21.65 21.65Q22.6 20.7 24 20.7Q25.4 20.7 26.35 21.65Q27.3 22.6 27.3 24Q27.3 25.4 26.35 26.35Q25.4 27.3 24 27.3ZM36.7 14.6Q35.3 14.6 34.35 13.65Q33.4 12.7 33.4 11.3Q33.4 9.9 34.35 8.95Q35.3 8 36.7 8Q38.1 8 39.05 8.95Q40 9.9 40 11.3Q40 12.7 39.05 13.65Q38.1 14.6 36.7 14.6ZM24 14.6Q22.6 14.6 21.65 13.65Q20.7 12.7 20.7 11.3Q20.7 9.9 21.65 8.95Q22.6 8 24 8Q25.4 8 26.35 8.95Q27.3 9.9 27.3 11.3Q27.3 12.7 26.35 13.65Q25.4 14.6 24 14.6ZM36.7 27.3Q35.3 27.3 34.35 26.35Q33.4 25.4 33.4 24Q33.4 22.6 34.35 21.65Q35.3 20.7 36.7 20.7Q38.1 20.7 39.05 21.65Q40 22.6 40 24Q40 25.4 39.05 26.35Q38.1 27.3 36.7 27.3ZM36.7 40Q35.3 40 34.35 39.05Q33.4 38.1 33.4 36.7Q33.4 35.3 34.35 34.35Q35.3 33.4 36.7 33.4Q38.1 33.4 39.05 34.35Q40 35.3 40 36.7Q40 38.1 39.05 39.05Q38.1 40 36.7 40Z" />
            </svg>

            <span className="text">
              <a href="https://thehartford.sharepoint.com">SharePoint</a>
            </span>
          </div>
        )}

        <div className="hartford">
          <img src={hartfordLogo} className="logo" alt="logo" />
          <div className="rightSide">
            <h2 className="p-0 m-0">{top}</h2>
            <h2 className="p-0 m-0">{bottom}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
