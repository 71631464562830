import axios from "axios";
import { ILink, ILinkDto } from "../types";

export const createLink = (link: ILinkDto): Promise<ILink> => {
    return axios
        .post<{ data: ILink }>("/api/links", link)
        .then((r) => r.data.data);
};

export const updateLink = (link: ILink): Promise<ILink> => {
    return axios
        .put<{ data: ILink }>(`/api/links/${link.LinkID}`, link)
        .then((r) => r.data.data);
};

export const deleteLink = (id: number): Promise<ILink> => {
    return axios
        .delete<{ data: ILink }>(`/api/links/${id}`)
        .then((r) => r.data.data);
};

export const getLinks = (eventID: number): Promise<ILink[]> => {
    return axios
        .get<{ data: ILink[] }>(`/api/links/${eventID}`)
        .then((r) => r.data.data);
};
