import React from "react";
import { EncoderOperation, IEncoder } from "../../Core/types";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";
import SaveIcon from "@material-ui/icons/Save";
import { Typography } from "@material-ui/core";

const statusMap = {
    [EncoderOperation.STARTED]: "Started",
    [EncoderOperation.STOPPED]: "Stopped",
    [EncoderOperation.ERROR]: "Error",
};

interface VideoEncoderEditProps {
    backgroundColor: string;
    encoder: IEncoder;
    cancel: () => void;
    status: EncoderOperation;
    onSubmitEdit: (encoder: IEncoder) => void;
}

const VideoEncoderEdit: React.FC<VideoEncoderEditProps> = ({
    encoder,
    cancel,
    status,
    onSubmitEdit,
    backgroundColor,
}): React.ReactElement => {
    const [editEncoder, setEditEncoder] = React.useState<IEncoder>(encoder);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditEncoder({ ...editEncoder, [e.target.name]: e.target.value });
    };
    return (
        <TableRow style={{ backgroundColor }}>
            <TableCell align="center">{encoder.EncoderID}</TableCell>
            <TableCell>
                <TextField
                    name="Name"
                    onChange={handleChange}
                    value={editEncoder.Name}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Name"
                />
            </TableCell>
            <TableCell>
                <TextField
                    name="URL"
                    onChange={handleChange}
                    value={editEncoder.URL}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Video Encoder URL"
                />
            </TableCell>
            <TableCell>
                <TextField
                    name="Username"
                    onChange={handleChange}
                    value={editEncoder.Username}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Username"
                />
            </TableCell>

            <TableCell>
                <TextField
                    name="Password"
                    onChange={handleChange}
                    value={editEncoder.Password}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Password"
                />
            </TableCell>
            <TableCell>
                <Typography
                    style={{
                        color:
                            status === EncoderOperation.STARTED
                                ? "green"
                                : status === EncoderOperation.STOPPED
                                ? "purple"
                                : status === EncoderOperation.ERROR
                                ? "red"
                                : "unset",
                    }}
                >
                    {statusMap[status]}
                </Typography>
            </TableCell>
            <TableCell className="white-space-nowrap">
                <Tooltip title="Save">
                    <IconButton
                        size="small"
                        style={{ marginRight: 12 }}
                        onClick={() => onSubmitEdit(editEncoder)}
                    >
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton size="small" onClick={() => cancel()}>
                        <UndoIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default VideoEncoderEdit;
