import React from "react";
import cidrRegex from "cidr-regex";
import { IpRangeDto } from "../../Core/types";

import * as IpRangeService from "../../Core/Services/IpRangeService";

import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";

interface IpRangeFormProps {
    submit: (ipRange: IpRangeDto) => void;
}
interface IPRangeErrors {
    IPv4?: string | null;
    Name?: string | null;
}
const IpRangeForm: React.FC<IpRangeFormProps> = ({
    submit,
}): React.ReactElement => {
    const [testIp, setTestIp] = React.useState<string>("");
    const [errors, setErrors] = React.useState<IPRangeErrors>({});
    const [testResults, setTestResults] = React.useState<{
        full: string;
        type: string;
        name: string;
    }>();

    const [newIpRange, setNewIpRange] = React.useState<IpRangeDto>({
        Name: "",
        IPv4: "",
        Type: "unicastWireless",
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrors({ ...errors, [e.target.name]: null });

        setNewIpRange({ ...newIpRange, [e.target.name]: e.target.value });
    };

    const sendTestIp = () => {
        IpRangeService.testIp(testIp).then(setTestResults);
    };

    const changeTestIp = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTestIp(e.target.value);
    };

    const validateIPv4 = (value: string): string | null => {
        if (value.trim().length < 1) return "Ip Range cannot be blank";
        const cidrTest = cidrRegex({ exact: true }).test(value.trim());
        if (!cidrTest) return "Invalid cidr";
        return null;
    };
    const validateName = (value: string): string | null => {
        if (value.trim().length < 1) return "Name cannot be blank";
        return null;
    };

    const validate = () => {
        setErrors({});
        const newErrors = {
            IPv4: validateIPv4(newIpRange.IPv4),
            Name: validateName(newIpRange.Name),
        };

        if (newErrors.Name || newErrors.IPv4) {
            setErrors(newErrors);
        }
        return newErrors.Name || newErrors.IPv4;
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.name === "IPv4") {
            if (errors?.IPv4) {
                setErrors({ ...errors, IPv4: undefined });
            }
            const IPv4 = validateIPv4(e.target.value);
            if (IPv4) {
                setErrors({ ...errors, IPv4 });
            }
        }
        if (e.target.name === "Name") {
            if (errors.Name) {
                setErrors({ ...errors, Name: undefined });
            }
            const Name = validateName(e.target.value);
            if (Name) {
                setErrors({ ...errors, Name });
            }
        }
    };

    const onSubmit = () => {
        const hasErrors = validate();

        if (!hasErrors) {
            submit(newIpRange);
            setNewIpRange({
                Name: "",
                IPv4: "",
                Type: "unicastWireless",
            });
        }
    };

    return (
        <div className="two-form__container">
            <div className="form-container mb-5">
                <Typography
                    variant="h4"
                    className="form__header"
                    style={{ margin: 0, padding: "4px 0" }}
                >
                    New IP Range
                </Typography>
                <div className="form">
                    <TextField
                        name="Name"
                        onChange={handleChange}
                        value={newIpRange.Name}
                        variant="outlined"
                        color="primary"
                        onBlur={handleBlur}
                        margin="dense"
                        size="small"
                        className="form-control"
                        error={!!errors.Name}
                        helperText={!!errors.Name && errors.Name}
                        label="Name"
                    />
                    <TextField
                        name="IPv4"
                        multiline
                        onChange={handleChange}
                        value={newIpRange.IPv4}
                        variant="outlined"
                        color="primary"
                        margin="dense"
                        size="small"
                        className="form-control"
                        onBlur={handleBlur}
                        error={!!errors.IPv4}
                        helperText={!!errors.IPv4 && errors.IPv4}
                        label="IP Range"
                    />
                    <FormControl
                        margin="dense"
                        color="primary"
                        variant="outlined"
                        className="form-control"
                    >
                        <InputLabel id="type-label">Type</InputLabel>

                        <Select
                            labelId="type-label"
                            id="type"
                            name="Type"
                            value={newIpRange.Type}
                            onChange={(e) =>
                                setNewIpRange({
                                    ...newIpRange,
                                    Type: e.target.value as
                                        | "unicastWireless"
                                        | "unicastRemote"
                                        | "multicast",
                                })
                            }
                            label="Type"
                        >
                            <MenuItem value="unicastWireless">
                                Unicast Wireless
                            </MenuItem>
                            <MenuItem value="unicastRemote">
                                Unicast Remote
                            </MenuItem>
                            <MenuItem value="multicast">Multicast</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Button onClick={onSubmit} variant="contained" color="primary">
                    Submit
                </Button>
            </div>
            <div className="form-container">
                <Typography
                    variant="h4"
                    className="form__header"
                    style={{ margin: 0, padding: "4px 0" }}
                >
                    Test IP Address
                </Typography>
                <div className="flex nowrap align-items-center">
                    <TextField
                        type="text"
                        style={{ marginRight: 8 }}
                        margin="dense"
                        size="small"
                        className="form-control"
                        onChange={changeTestIp}
                        variant="outlined"
                        name="ipaddress"
                        label="IP Address"
                        value={testIp}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => sendTestIp()}
                    >
                        Test
                    </Button>
                </div>
                {testResults && (
                    <>
                        <Typography variant="h6">
                            Using: {testResults.type}
                        </Typography>

                        {testResults.name && (
                            <>(from rule: {testResults.name})</>
                        )}
                        {!testResults.name && <>(no rule found)</>}

                        <pre
                            title={testResults.full}
                            onClick={() =>
                                navigator.clipboard.writeText(testResults.full)
                            }
                            style={{
                                width: "90%",
                                overflow: "auto",
                                textAlign: "left",
                                display: "inline-block",
                            }}
                        >
                            {testResults.full}
                        </pre>
                    </>
                )}
            </div>
        </div>
    );
};

export default IpRangeForm;
