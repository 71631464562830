import React from "react";
import "../Templates.css";
import { IEvent } from "../../Core/types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import EventTable from "../../Shared/EventTable";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import * as EventService from "../../Core/Services/EventService";
interface TemplatesContainerProps {}

const TemplatesContainer: React.FC<TemplatesContainerProps> = (): React.ReactElement => {
    const [events, setEvents] = React.useState<IEvent[]>([]);
    const [warning, setWarning] = React.useState<boolean>(false);
    const [confirmationDialog, setConfirmationDialog] = React.useState<
        number | false
    >(false);
    React.useEffect(() => {
        EventService.getTemplateEvents()
            .then(setEvents)
            .catch((err) => {
                console.error(
                    "[TEMPLATE/API] Get Event Template Failed",
                    err.message
                );
            });
    }, [setEvents]);

    const handleUpdate = (event: IEvent): Promise<{ errors: any } | null> => {
        return EventService.updateEvent(event)
            .then((updatedEvent) => {
                if (!updatedEvent.IsTemplate) {
                    setEvents(
                        events.filter((e) => e.EventID !== updatedEvent.EventID)
                    );
                } else {
                    setEvents(
                        events.map((e) =>
                            e.EventID === updatedEvent.EventID
                                ? updatedEvent
                                : e
                        )
                    );
                }
                return null;
            })
            .catch((err) => {
                console.warn(
                    "[TEMPLATE/API] Update Template Event Failed",
                    err
                );
                return { errors: err };
            });
    };

    const handleDelete = (event: IEvent) => {
        if (events.length <= 1) {
            setWarning(true);
        } else {
            EventService.deleteEvent(event.EventID)
                .then(() => {
                    setEvents(
                        events.filter((e) => e.EventID !== event.EventID)
                    );
                    return null;
                })
                .catch((err) => {
                    console.warn(
                        "[TEMPLATE/API] Delete Event Template Failed",
                        err
                    );
                    return { errors: err };
                });
        }
    };

    const handleDuplicate = (event: IEvent) => {
        EventService.duplicateEvent(event)
            .then((newEvent) => setEvents([newEvent, ...events]))
            .catch((err) => {
                console.warn(
                    "[TEMPLATE/API] Duplicate Event Template Failed",
                    err
                );
            });
    };

    const handleClose = () => {
        setWarning(false);
    };
    const deleteEvent = (id: number) => {
        setConfirmationDialog(false);
        EventService.deleteEvent(id)
            .then(() => setEvents(events.filter((e) => e.EventID !== id)))
            .catch((err) =>
                console.error("[ERROR] Event couldn't be deleted", err.message)
            );
    };
    const handleMarkActive = (_event: IEvent) => {};
    const handleMarkAsTemplate = (_event: IEvent) => {};
    const handleMarkRebroadcast = (_event: IEvent) => {};
    return (
        <>
            <Dialog
                open={confirmationDialog !== false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Event?"}
                </DialogTitle>

                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() =>
                            deleteEvent(confirmationDialog as number)
                        }
                        color="secondary"
                    >
                        Yes
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={handleClose}
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={warning}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"You cannot delete the last template"}
                </DialogTitle>

                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        color="secondary"
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <EventTable
                events={events}
                isEventTable={false}
                isReplayTable={false}
                isTemplateTable={true}
                onDelete={handleDelete}
                onDuplicate={handleDuplicate}
                onEditSubmit={handleUpdate}
                onMarkActive={handleMarkActive}
                onMarkAsTemplate={handleMarkAsTemplate}
                onMarkRebroadcast={handleMarkRebroadcast}
            />
        </>
    );
};

export default TemplatesContainer;
