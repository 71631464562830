import React from "react";
import { IFrame } from "../../../Core/types";
import Table from "@material-ui/core/Table";
import FrameRow from "./FrameRow";
import FrameEdit from "./FrameEdit";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";

interface FrameListProps {
    frames: IFrame[];
    deleteFrame: (id: number) => void;
    submitEdit: (frame: IFrame) => void;
    handleToggle: (id: number) => void;
}

const FrameList: React.FC<FrameListProps> = ({
    frames,
    submitEdit,
    deleteFrame,
    handleToggle,
}): React.ReactElement => {
    const [selectedFrame, setSelectedFrame] = React.useState<IFrame | null>(
        null
    );

    const handleSubmit = (frame: IFrame) => {
        submitEdit(frame);
        setSelectedFrame(null);
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow className="table__head">
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Url</TableCell>
                        <TableCell align="center">Active</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {frames.map((frame, index) => {
                        const bg = index % 2 === 0 ? "#f6f5f7" : "inherit";
                        return selectedFrame?.FrameID === frame.FrameID ? (
                            <FrameEdit
                                cancel={() => setSelectedFrame(null)}
                                backgroundColor={bg}
                                key={frame.FrameID}
                                frame={frame}
                                submit={handleSubmit}
                            />
                        ) : (
                            <FrameRow
                                key={frame.FrameID}
                                frame={frame}
                                toggleActive={handleToggle}
                                backgroundColor={bg}
                                deleteFrame={deleteFrame}
                                selectFrame={setSelectedFrame}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FrameList;
