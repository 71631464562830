import {
    EncoderOperation,
    EncoderStatusResponse,
    EncoderResponse,
} from "../types";

export const humanReadableStatus = (status: EncoderOperation) => {
    if (status === 1) {
        return "Stopped";
    } else if (status === 2) {
        return "Started";
    } else if (status === 3) {
        return "Error";
    }
    return "Error";
};

export const processEncoderDataForResponse = (
    remoteResponse: EncoderStatusResponse
): EncoderResponse => {
    const status = remoteResponse.broadcasting ? "Started" : "Stopped";
    const output = remoteResponse.output;
    let Status =
        "broadcasting" in remoteResponse
            ? remoteResponse.broadcasting
                ? EncoderOperation.STARTED
                : EncoderOperation.STOPPED
            : EncoderOperation.ERROR;
    let descriptive = "";
    let extra = "";

    // const normalStates = ["Stopped", "Started"];

    // if (
    //     normalStates.includes(local) &&
    //     normalStates.includes(remote) &&
    //     localStatus !== remoteResponse.Status
    // ) {
    //     status = "Error";
    //     Status = EncoderOperation.ERROR;
    if (status === "Started" && remoteResponse.meta.EventID) {
        extra += ` for event# ${remoteResponse.meta.EventID}`;
    } else if (status === "Started" && !remoteResponse.meta.EventID) {
        extra += ` but no EventID was found. This could be caused by manually starting the encoder.`;
    } else {
        extra += ".";
    }

    descriptive = `Remote is ${status}${extra}`;
    // } else if (remoteResponse.error) {
    //     status = "Error";
    //     Status = EncoderOperation.ERROR
    //     descriptive = `An error occurred on the encoder. Please check the encoder (${EncoderID}: ${remoteResponse.error})`;
    // } else if (remoteResponse.Status) {
    //     descriptive = "Encoder reported back normally";
    //     status = humanReadableStatus(remoteResponse.Status);
    //     Status = remoteResponse.Status;
    // } else {
    //     // Unknown
    //     status = "Unknown Error";
    //     Status = remoteResponse.Status;
    //     descriptive = `An unknown error occurred. The encoder manager might be down? ${remoteResponse.Status}`;
    // }

    return {
        status,
        Status,
        descriptive,
        output,
    };
};
