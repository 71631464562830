import React from "react";
interface ClosedCaptionProps {
    ccUrl: string;
    eventTitle: string;
}

const ClosedCaption: React.FC<ClosedCaptionProps> = ({
    ccUrl,
    eventTitle,
}): React.ReactElement => {
    return (
        <div className="player--closed-caption">
            <iframe
                style={{ border: "none", borderRadius: 5 }}
                width="100%"
                height="100%"
                src={ccUrl}
                title={eventTitle}
            />
        </div>
    );
};

export default ClosedCaption;
