import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormGroup,
    makeStyles,
    TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Credentials } from "../../Core/types";

const useStyles = makeStyles((theme) => ({
    mb: {
        marginBottom: 12,
    },
    warn: {
        color: theme.palette.warning.main,
    },
}));
enum AuthStatus {
    PENDING,
    NOT_AUTHENTICATED,
    AUTHENTICATED,
    ERROR,
}
interface LoginDialogProps {
    authenticate: (credentials: Credentials) => void;
    error: string | null;
    status: AuthStatus;
}

const LoginDialog: React.FC<LoginDialogProps> = ({
    authenticate,
    status,
    error,
}) => {
    const classes = useStyles();
    const [credentials, setCredentials] = useState<Credentials>({
        AccessID: "",
        AccessPWD: "",
    });

    const attemptLogin = () => {
        authenticate(credentials);
    };

    const keyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            attemptLogin();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setCredentials({ ...credentials, [event.target.name]: value });
    };

    return (
        <Dialog open={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Please Login</DialogTitle>
            <DialogContent>
                <form>
                    <FormGroup>
                        <TextField
                            type="text"
                            name="AccessID"
                            label="Username"
                            className={classes.mb}
                            onKeyPress={keyPressed}
                            onChange={handleChange}
                            autoComplete="off"
                            value={credentials.AccessID}
                        />

                        <TextField
                            type="password"
                            name="AccessPWD"
                            label="Password"
                            className={classes.mb}
                            onKeyPress={keyPressed}
                            onChange={handleChange}
                            value={credentials.AccessPWD}
                            autoComplete="off"
                        />

                        <Button className={classes.mb} onClick={attemptLogin}>
                            Login
                        </Button>

                        {status === AuthStatus.PENDING && (
                            <>Please wait, checking login</>
                        )}
                        {error && (
                            <p className={`${classes.warn} text-center m-0 p-0`}>{error}</p>
                        )}
                    </FormGroup>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default LoginDialog;
