import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

import { ILink } from "../../../Core/types";
interface LinksRowProps {
    backgroundColor: string;
    link: ILink;
    selectLink: (frame: ILink) => void;
    deleteLink: (id: number) => void;
}

const LinksRow: React.FC<LinksRowProps> = ({
    deleteLink,
    link,
    selectLink,
    backgroundColor,
}): React.ReactElement => {
    return (
        <TableRow style={{ backgroundColor }}>
            <TableCell align="center" style={{ padding: "0 4px" }}>
                {link.LinkID}
            </TableCell>
            <TableCell align="center">{link.Name}</TableCell>

            <TableCell align="center">{link.URL}</TableCell>

            <TableCell align="right">
                <p className="white-space-nowrap">
                    <IconButton onClick={() => selectLink(link)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteLink(link.LinkID)}>
                        <DeleteIcon />
                    </IconButton>
                </p>
            </TableCell>
        </TableRow>
    );
};

export default LinksRow;
