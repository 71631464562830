import React from "react";
import "../../Events.css";
import { useParams } from "react-router";
import { IAttend, IEvent } from "../../../Core/types";

import Header from "../../../Header/Header";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import FormGroup from "@material-ui/core/FormGroup";
import TableContainer from "@material-ui/core/TableContainer";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import * as EventService from "../../../Core/Services/EventService";
import * as AttendanceService from "../../../Core/Services/AttendanceService";

const PAGE_SIZE = 200;

type Filters = {
  showIP: boolean;
  showOS: boolean;
  showAgent: boolean;
  showMobile: boolean;
  showBrowser: boolean;
  showServerHost: boolean;
  showConnectionType: boolean;
};

const connectionTypeMap = {
  unicastRemote: "Unicast Remote",
  unicastWireless: "Unicast Wireless",
  multicast: "Multicast",
};

interface PagerProps {
  next: () => void;
  set: (index: number) => void;
  previous: () => void;
  currentPage: number;
  count: number;
}

const Pager: React.FC<PagerProps> = ({
  currentPage,
  next,
  previous,
  set,
  count,
}): React.ReactElement => {
  const styles = {
    padding: "1rem",
  };

  const pageCount = Math.ceil(count / PAGE_SIZE);

  return (
    <div>
      {pageCount && (
        <>
          <Button style={styles} onClick={previous}>
            Previous
          </Button>
          <Button style={styles} onClick={next}>
            Next
          </Button>
          Current page { currentPage+1 } (of {pageCount})
        </>
      )}
    </div>
  );
};

const AttendanceList: React.FC = (): React.ReactElement => {
  const [filters, setFilters] = React.useState<Filters>({
    showIP: false,
    showOS: false,
    showMobile: true,
    showAgent: false,
    showBrowser: true,
    showServerHost: false,
    showConnectionType: true,
  });
  const [event, setEvent] = React.useState<IEvent | null>(null);
  const { id: eventID } = useParams<{ id: string }>();
  const [page, setPage] = React.useState<number>(0);
  const [count, setCount] = React.useState<number>(0);

  const [attendanceList, setAttendanceList] = React.useState<IAttend[]>([]);

  React.useEffect(() => {
    AttendanceService.getAttendsForEvent(+eventID, page)
      .then(({ attendees, count }) => {
        setAttendanceList([...attendees]);
        setCount(count);
      })
      .catch((err) =>
        console.log("[ATTENDAttendanceList/API] Get Attendance List Failed", err.message)
      );
    EventService.getEvent(+eventID)
      .then(setEvent)
      .catch((err) => console.log("[EVENT/API] Get Event Failed", err.message));
  }, [eventID, page]);

  function nextPage() {
      console.log('Going to the next page', page);
    if (page * PAGE_SIZE < count) {
      setPage(page + 1);
    }
  }

  function previousPage() {
      console.log('Going to the previous page', page);
    if (page > 0) {
      setPage(page - 1);
    }
  }
  function getXlsx() {
    document.location.href = `/api/attends/xlsx/${eventID}`;
  }

  return (
    <>
      <Header top={event?.Title || ""} bottom="Event Links" />
      <div className="main-content">
        <div
          className="table-and-form__wrapper"
          style={{ gridTemplateColumns: "auto 230px" }}
        >
          <div className="table-and-form__table">
            <Pager
              count={count}
              set={setPage}
              currentPage={page}
              next={nextPage}
              previous={previousPage}
            />

            <TableContainer>
              <Table size="small" stickyHeader aria-label="attendance table">
                <TableHead>
                  <TableRow className="table__head">
                    <TableCell>User</TableCell>
                    {filters.showServerHost && (
                      <TableCell align="center">Server/Host</TableCell>
                    )}
                    {filters.showIP && <TableCell align="center">IP</TableCell>}
                    {filters.showBrowser && (
                      <TableCell align="center">Browser</TableCell>
                    )}
                    {filters.showMobile && (
                      <TableCell align="center">Mobile</TableCell>
                    )}
                    {filters.showOS && <TableCell align="center">OS</TableCell>}
                    {filters.showAgent && (
                      <TableCell align="center">Agent</TableCell>
                    )}
                    {filters.showConnectionType && (
                      <TableCell align="center">Connection Type</TableCell>
                    )}
                    <TableCell align="center">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceList.map((row, i) => (
                    <TableRow
                      style={{
                        backgroundColor: i % 2 === 0 ? "#f6f5f7" : "inherit",
                      }}
                      key={row.AttendID}
                    >
                      <TableCell component="th" scope="row">
                        <p>ID: {row.AttendID}</p>
                        <p>User: {row.User}</p>
                      </TableCell>
                      {filters.showServerHost && (
                        <TableCell>
                          <p>Server: {row.Host}</p>
                          <p>Host: {row.Host}</p>
                        </TableCell>
                      )}
                      {filters.showIP && (
                        <TableCell>
                          <p>Local: {row.Local}</p>
                          <p>Remote: {row.Remote}</p>
                        </TableCell>
                      )}
                      {filters.showBrowser && (
                        <TableCell align="left">
                          <p>Browser: {row.Browser}</p>
                          <p>Type: {row.BrowserType}</p>
                          <p>Version: {row.Version}</p>
                        </TableCell>
                      )}
                      {filters.showMobile && (
                        <TableCell align="left">
                          <p>IsMobile: {row.IsMobile}</p>
                          <p>Manufacturer: {row.MobileManufacturer}</p>
                          <p>Model: {row.MobileModel || ""}</p>
                        </TableCell>
                      )}
                      {filters.showOS && (
                        <TableCell>
                          <p>Platform: {row.Platform}</p>
                          <p>OS: {row.OS || "Unknown"}</p>
                        </TableCell>
                      )}
                      {filters.showAgent && (
                        <TableCell>
                          <p>{row.Agent}</p>
                        </TableCell>
                      )}
                      {filters.showConnectionType && (
                        <TableCell align="center">
                          <p>{connectionTypeMap[row.ConnectionType]}</p>
                        </TableCell>
                      )}
                      <TableCell align="center">
                        <p>{new Date(row.CreatedDate).toLocaleDateString()}</p>
                        <p>{new Date(row.CreatedDate).toLocaleTimeString()}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Pager
              count={count}
              set={setPage}
              currentPage={page}
              next={nextPage}
              previous={previousPage}
            />
          </div>
          <div className="table-and-form__form">
            <div className="questions-utils__container" style={{ width: 220 }}>
              <FormGroup
                style={{
                  backgroundColor: "#e4e3e4",
                  padding: 8,
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showServerHost"
                      checked={filters.showServerHost}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showServerHost: !filters.showServerHost,
                        })
                      }
                    />
                  }
                  label="Show ServerHost"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showIP"
                      checked={filters.showIP}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showIP: !filters.showIP,
                        })
                      }
                    />
                  }
                  label="Show IP"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showBrowser"
                      checked={filters.showBrowser}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showBrowser: !filters.showBrowser,
                        })
                      }
                    />
                  }
                  label="Show Browser"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showMobile"
                      checked={filters.showMobile}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showMobile: !filters.showMobile,
                        })
                      }
                    />
                  }
                  label="Show Mobile"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showOS"
                      checked={filters.showOS}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showOS: !filters.showOS,
                        })
                      }
                    />
                  }
                  label="Show OS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showAgent"
                      checked={filters.showAgent}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showAgent: !filters.showAgent,
                        })
                      }
                    />
                  }
                  label="Show Agent"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showAgent"
                      checked={filters.showConnectionType}
                      onChange={() =>
                        setFilters({
                          ...filters,
                          showConnectionType: !filters.showConnectionType,
                        })
                      }
                    />
                  }
                  label="Show Agent"
                />
              </FormGroup>
              <div
                className="questions-utils__buttons"
                style={{ backgroundColor: "#e4e3e4" }}
              >
                <Button
                  style={{ marginBottom: 8, width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={() => document.location.reload()}
                >
                  Refresh Attendance
                </Button>
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={getXlsx}
                  href={""}
                >
                  Export to Excel
                </Button>
              </div>
              <div className="questions-utils__attendance">
                <h6 className="questions-utils__attendance-header">
                  Attendance
                </h6>
                <div className="questions-utils__attendance-total">
                  <span>video.thehartford.com</span>
                  <span>{count}</span>
                </div>
                <div className="questions-utils__attendance-total">
                  <span>Total</span>
                  <span>{count}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceList;
