import React from "react";
import "../Player.css";
import Button from "@material-ui/core/Button";
import ClosedCaption from "./ClosedCaption";
import videoJs from "video.js";
import "video.js/dist/video-js.min.css";
import { IFrame } from "../../Core/types";
interface VideoPlayerProps {
    src: string;
    eventIsFrame: boolean;
    title: string | null;
    description: string | null;
    frames: IFrame[];
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props): React.ReactElement => {
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const [player, setPlayer] = React.useState<videoJs.Player | null>(null);
    const [closedCaption, setClosedCaption] = React.useState<boolean>(false);

    const handleFullscreen = () => {
        if (player) {
            player.requestFullscreen();
        }
    };

    React.useEffect(() => {
        if (!player && videoRef.current) {
            const vjsPlayer = videoJs(videoRef.current, {
                preload: "auto",
                fluid: true,
                controls: true,
                muted: true,
                src: props.src,
                controlBar: {
                    pictureInPictureToggle: false,
                    subtitlesButton: false,
                    subsCapsButton: false,
                    audioTrackButton: false,
                    captionsButton: false,
                },
                html5: {
                    vhs: {
                        smoothQualityChange: true,
                        overrideNative: true,
                    },
                },
            });
            setPlayer(vjsPlayer);
            vjsPlayer.on("error", (err) => {
                console.log("VideoJS Error.", err);
                console.log("VideoJS source:", props.src);
            });
        } else if (player) {
            return () => {
                player.dispose();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (player && props.src) {
            player.src(props.src);
            player
                .play()
                ?.then(() => {
                    player.muted(false);
                })
                ?.catch((err) =>
                    console.warn("The player could not play:", err)
                );
        }
    }, [props.src, player]);
    return (
        <>
            <div className="player--video__container">
                <div className="player--video__video_section">
                    <div data-vjs-player>
                        <video ref={videoRef} className="video-js" />
                    </div>

                    <div className="player--video__actions">
                        {props.eventIsFrame && (
                            <Button
                                disabled={props.frames.length === 0}
                                className="closed-caption"
                                variant="contained"
                                onClick={() => setClosedCaption(!closedCaption)}
                                color="primary"
                            >
                                {props.frames[0]?.Name || "Closed Captions"}
                            </Button>
                        )}

                        <Button
                            className="fullscreen"
                            variant="contained"
                            color="primary"
                            onClick={handleFullscreen}
                        >
                            Fullscreen
                        </Button>
                    </div>
                </div>
                {closedCaption && props.frames[0]?.URL && (
                    <div className="player--video__video_section">
                        <ClosedCaption
                            ccUrl={props.frames[0].URL}
                            eventTitle={props.title || ""}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default VideoPlayer;
