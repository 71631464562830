import React from "react";
import { IEncoder } from "../../Core/types";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
interface EncoderTestDialogProps {
    encoders: IEncoder[];
    handleClose: () => void;
    testEncoder: (encoder: IEncoder) => void;
}

const EncoderTestDialog: React.FC<EncoderTestDialogProps> = (
    props
): React.ReactElement => {
    const [selectedEncoderID, setSelectedEncoderID] = React.useState<number>(0);
    const handleConfirmTest = () => {
        if (selectedEncoderID !== 0) {
            const encoder = props.encoders.find(
                (e) => e.EncoderID === selectedEncoderID
            );
            if (encoder) {
                props.testEncoder(encoder);
                setSelectedEncoderID(0);
            }
        } else {
            props.handleClose();
            setSelectedEncoderID(0);
        }
    };
    return (
        <Dialog open={true} onClose={props.handleClose}>
            <DialogTitle>Choose an encoder</DialogTitle>
            <DialogContent>
                {props.encoders.length ? (
                    <FormControl
                        size="small"
                        margin="dense"
                        color="primary"
                        variant="outlined"
                        className="form-control"
                    >
                        <InputLabel id="encoder_label">Encoder</InputLabel>

                        <Select
                            labelId="encoder_label"
                            id="encoder_id"
                            name="Encoder"
                            value={selectedEncoderID || ""}
                            onChange={(e) =>
                                setSelectedEncoderID(e.target.value as number)
                            }
                            label="Encoder"
                        >
                            {props.encoders.map((e, i) => (
                                <MenuItem
                                    key={`${e.EncoderID}_${i}`}
                                    value={e.EncoderID}
                                >
                                    {e.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <h4>No live encoders</h4>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleConfirmTest}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EncoderTestDialog;
