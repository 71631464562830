import axios from "axios";
import { IQuestion, IQuestionDto } from "../types";

export const createQuestion = (question: IQuestionDto): Promise<IQuestion> => {
    return axios
        .post<{ data: IQuestion }>("/api/questions", question)
        .then((r) => r.data.data);
};

export const updateQuestion = (question: IQuestion): Promise<IQuestion> => {
    return axios
        .put<{ data: IQuestion }>(
            `/api/questions/${question.QuestionID}`,
            question
        )
        .then((r) => r.data.data);
};

export const deleteQuestion = (questionID: number): Promise<IQuestion> => {
    return axios
        .delete<{ data: IQuestion }>(`/api/questions/${questionID}`)
        .then((r) => r.data.data);
};

export const getQuestionsForEvent = (eventId: number): Promise<IQuestion[]> => {
    return axios
        .get<{ data: IQuestion[] }>(`/api/questions/${eventId}`)
        .then((r) => r.data.data);
};

export const getLiveEventQuestions = (): Promise<{
    questions: IQuestion[];
    count: number;
} | null> => {
    return axios
        .get<{ data: { questions: IQuestion[]; count: number } | null }>(
            "/api/questions/live"
        )
        .then((r) => r.data.data);
};

export const getLiveEventPresenterQuestion = (): Promise<
    (IQuestion & { eventTitle: string }) | null
> => {
    return axios
        .get<{ data: (IQuestion & { eventTitle: string }) | null }>(
            "/api/questions/presenter"
        )
        .then((r) => r.data.data);
};
