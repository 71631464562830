import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
interface EncoderStopDialogProps {
    handleConfirmYes: () => void;
    handleConfirmNo: () => void;
}

const EncoderStopDialog: React.FC<EncoderStopDialogProps> = (
    props
): React.ReactElement => {
    return (
        <Dialog open={true} onClose={props.handleConfirmNo}>
            <DialogContent>
                Are you sure you want to stop the encoder?
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={props.handleConfirmYes}
                >
                    Yes
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.handleConfirmNo}
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EncoderStopDialog;
