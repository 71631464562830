import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import LinkIcon from "@material-ui/icons/Link";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import VideocamIcon from "@material-ui/icons/Videocam";
import SlideshowIcon from '@material-ui/icons/Slideshow';
import AssessmentIcon from "@material-ui/icons/Assessment";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import "./Navigation.css";

const Sidenav: React.FC = (): React.ReactElement => {
    const [eventId, setEventID] = React.useState<null | number>(null);
    const location = useLocation();
    React.useEffect(() => {
        if (location.pathname.includes("/events/")) {
            const split = location.pathname.split("/");
            if (!isNaN(+split[3])) {
                setEventID(+split[3]);
            } else {
                setEventID(null);
            }
        } else {
            setEventID(null);
        }
    }, [eventId, location]);
    return (
        <div className={`sidenavContainer ${eventId ? "nested" : ""}`}>
            <List>
                <ListItem>
                    <Link
                        className="link"
                        component={RouterLink}
                        to="/admintools/templates"
                    >
                        Templates
                    </Link>
                </ListItem>
                <ListItem>
                    <Link
                        className="link"
                        component={RouterLink}
                        to="/admintools/events"
                    >
                        Events
                    </Link>
                </ListItem>
                {eventId && (
                    <React.Fragment>
                        <ListItem>
                            <div className="box">
                                <Typography
                                    variant="h6"
                                    style={{
                                        backgroundColor: "#e4e3e4",
                                        textAlign: "center",
                                    }}
                                >
                                    Event Details
                                </Typography>
                                <div className="innerList">
                                    <Link
                                        className="innerLink"
                                        component={RouterLink}
                                        to={`/admintools/events/${eventId}/video-streams`}
                                    >
                                        <VideocamIcon /> Video Streams
                                    </Link>
                                    <Link
                                        className="innerLink"
                                        component={RouterLink}
                                        to={`/admintools/events/${eventId}/frames`}
                                    >
                                        <AssessmentIcon /> Frames
                                    </Link>
                                    <Link
                                        className="innerLink"
                                        component={RouterLink}
                                        to={`/admintools/events/${eventId}/links/slido`}
                                    >
                                        <SlideshowIcon /> Slido
                                    </Link>
                                    <Link
                                        className="innerLink"
                                        component={RouterLink}
                                        to={`/admintools/events/${eventId}/links`}
                                    >
                                        <LinkIcon /> Links
                                    </Link>
                                    <Link
                                        className="innerLink"
                                        component={RouterLink}
                                        to={`/admintools/events/${eventId}/questions`}
                                    >
                                        <ContactSupportIcon /> Questions
                                    </Link>
                                    <Link
                                        className="innerLink"
                                        component={RouterLink}
                                        to={`/admintools/events/${eventId}/attendance`}
                                    >
                                        <SupervisorAccountIcon /> Attendance
                                    </Link>
                                </div>
                            </div>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                )}
                <ListItem>
                    <Link component={RouterLink} to="/admintools/replays">
                        Replays
                    </Link>
                </ListItem>
                <ListItem>
                    <Link component={RouterLink} to={`/admintools/ip-ranges`}>
                        IP Ranges
                    </Link>
                </ListItem>
                <ListItem>
                    <Link
                        component={RouterLink}
                        to={`/admintools/video-encoders`}
                    >
                        Vivoh Encoders
                    </Link>
                </ListItem>
                <ListItem className="spacer">
                    <Link
                        component={RouterLink}
                        to="/admintools/active-questions"
                    >
                        Active Questions
                    </Link>
                </ListItem>
            </List>
        </div>
    );
};

export default Sidenav;
