import axios, { AxiosError } from "axios";

export const getUsername = async () => {
    try {
        const response = await axios.get<string>("/script/user.aspx");
        if (response.data) {
            return response.data.trim();
        }
    } catch (error) {
        return (error as AxiosError).response?.data;
    }
};

export const sendUsername = async (username: string) => {
    try {
        await axios.post("/api/users/set-username", { username });
    } catch (error) {
        console.log("Unable to send username", error);
        return (error as AxiosError).response?.data;
    }
};
