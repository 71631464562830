import React from "react";
import { Redirect, Route, Switch } from "react-router";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import EventRouter from "./EventRouter";
import Sidenav from "../Navigation/Sidenav";
import Moderator from "../Moderator/Moderator";
import Presenter from "../Presenter/Presenter";
import PlayerContainer from "../Player/Containers/PlayerContainer";
import ReplaysContainer from "../Replays/Containers/ReplaysContainer";
import IpRangesContainer from "../IpRanges/Containers/IpRangesContainer";
import TemplatesContainer from "../Templates/Containers/TemplatesContainer";
import VideoEncoderContainer from "../VideoEncoders/Containers/VideoEncoderContainer";

import * as EventService from "../Core/Services/EventService";

const ActiveQuestions: React.FC = () => {
    const [eventId, setEventId] = React.useState<number | null>(null);
    const [loadComplete, setLoadComplete] = React.useState<boolean>(false);


    React.useEffect(() => {
        EventService.getLiveEvent()
            .then((data) => {
                if (data && data.event && data.event.EventID) {
                    setEventId(data.event.EventID);
                }
                setLoadComplete(true);
            })
            .catch();
    }, [setEventId, setLoadComplete]);
    return loadComplete ? (
        eventId ? (
            <Redirect to={`/admintools/events/${eventId}/questions`} />
        ) : (
            <Redirect to="/admintools/events" />
        )
    ) : (
        <>Checking for live event</>
    );
};

const MainRouter: React.FC = (): React.ReactElement => {
    return (
        <>
            <Switch>
                <Route path="/player">
                    <PlayerContainer />
                </Route>
                <Route path="/moderator">
                    <Moderator />
                </Route>
                <Route path="/presenter">
                    <Presenter />
                </Route>

                <Route path="/admintools/templates">
                    <Header top="Video Streaming" bottom="Templates List" />
                    <div className="main-content">
                        <TemplatesContainer />
                    </div>
                    <Sidenav />
                    <Footer />
                </Route>
                <Route path="/admintools/events">
                    <EventRouter />
                    <Sidenav />
                    <Footer />
                </Route>
                <Route path="/admintools/ip-ranges">
                    <Header top="IP Ranges" bottom="" />
                    <div className="main-content">
                        <IpRangesContainer />
                    </div>
                    <Sidenav />
                    <Footer />
                </Route>
                <Route path="/admintools/video-encoders">
                    <Header top="Video Encoders" bottom="" />
                    <div className="main-content">
                        <VideoEncoderContainer />
                    </div>
                    <Sidenav />
                    <Footer />
                </Route>
                <Route path="/admintools/replays">
                    <Header top="Video Streaming" bottom="Replays" />
                    <div className="main-content">
                        <ReplaysContainer />
                    </div>
                    <Sidenav />
                    <Footer />
                </Route>
                <Route path="/admintools/active-questions">
                    <Header top="Video Streaming" bottom="Active Questions" />
                    <div className="main-content">
                        <ActiveQuestions />
                    </div>
                    <Sidenav />
                    <Footer />
                </Route>
                <Redirect path="/admintools" to="/admintools/events" />
                <Route
                    path="**"
                    render={({ location }) => (
                        <Redirect to={`/player${location.search}`} />
                    )}
                />
            </Switch>
        </>
    );
};

export default MainRouter;
