import React from "react";
import { useParams } from "react-router";
import { IEvent, IFrame, IFrameDto } from "../../../Core/types";
import FrameForm from "./FrameForm";
import FrameList from "./FrameList";
import * as FrameService from "../../../Core/Services/FrameService";
import * as EventService from "../../../Core/Services/EventService";
import Header from "../../../Header/Header";
import axios, { CancelToken } from "axios";

const FrameContainer: React.FC = (): React.ReactElement => {
    const [frames, setFrames] = React.useState<IFrame[]>([]);
    const [event, setEvent] = React.useState<IEvent | null>(null);
    const { id } = useParams<{ id: string }>();

    const handleSubmit = (frame: IFrameDto) => {
        FrameService.createFrame(frame)
            .then((newFrame) => setFrames([newFrame, ...frames]))
            .catch((err) =>
                console.log("[FRAME/API] Create Frame Failed", err.message)
            );
    };

    const handleDeleteFrame = (id: number) => {
        FrameService.deleteFrame(id)
            .then(() => setFrames(frames.filter((f) => f.FrameID !== id)))
            .catch((err) =>
                console.log("[FRAME/API] Delete Frame Failed", err.message)
            );
    };

    const handleEdit = (frame: IFrame) => {
        FrameService.updateFrame(frame)
            .then((updatedFrame) =>
                setFrames(
                    frames.map((f) =>
                        f.FrameID === updatedFrame.FrameID
                            ? updatedFrame
                            : updatedFrame.IsActive
                            ? { ...f, IsActive: false }
                            : f
                    )
                )
            )
            .catch((err) =>
                console.log("[FRAME/API] Update Frame Failed", err.message)
            );
    };

    const handleToggle = (id: number) => {
        let frameToEdit;
        const newFrames = frames.map((f) =>
            f.FrameID === id
                ? (frameToEdit = { ...f, IsActive: !f.IsActive })
                : { ...f, IsActive: false }
        );
        if (frameToEdit) {
            FrameService.updateFrame(frameToEdit)
                .then(() => setFrames(newFrames))
                .catch((err) =>
                    console.log("[FRAME/API] Update Frame Failed", err.message)
                );
        }
    };

    const getFrames = React.useCallback(
        (unmounted: boolean, cancelToken: CancelToken) => {
            FrameService.getFramesForEvent(+id, cancelToken)
                .then((frames) => {
                    if (!unmounted) {
                        setFrames(frames);
                    }
                })
                .catch((err) =>
                    console.log("[FRAME/API] Get Frames Failed", err.message)
                );
        },
        [id, setFrames]
    );
    const getEvent = React.useCallback(
        (unmounted: boolean, cancelToken: CancelToken) => {
            EventService.getEvent(+id, cancelToken)
                .then((e) => {
                    if (!unmounted) {
                        setEvent(e);
                    }
                })
                .catch((err) =>
                    console.log("[EVENT/API] Get Event Failed", err.message)
                );
        },
        [id, setEvent]
    );

    React.useEffect(() => {
        let unmounted = false;
        let source = axios.CancelToken.source();
        if (id) {
            getFrames(unmounted, source.token);
            getEvent(unmounted, source.token);
        }
        return () => {
            unmounted = true;
            source.cancel("Canceling cleanup");
        };
    }, [id, getFrames, getEvent]);
    return (
        <>
            <Header top={event?.Title || ""} bottom="Event Frames" />
            <div className="main-content">
                <div className="table-and-form__wrapper">
                    <div className="table-and-form__table">
                        <FrameList
                            handleToggle={handleToggle}
                            deleteFrame={handleDeleteFrame}
                            frames={frames}
                            submitEdit={handleEdit}
                        />
                    </div>
                    <div className="table-and-form__form">
                        <FrameForm eventId={+id} submit={handleSubmit} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FrameContainer;
