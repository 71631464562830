import React from "react";
import "./Questions.css";
import { useParams } from "react-router";
import { IEvent, IQuestion } from "../../../Core/types";

import QuestionRow from "./QuestionRow";
import Header from "../../../Header/Header";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

import * as EventService from "../../../Core/Services/EventService";
import * as AttendService from "../../../Core/Services/AttendanceService";
import * as QuestionService from "../../../Core/Services/QuestionService";
import useInterval from "../../../Core/Hooks/useInterval";
import { QUESTION_REFRESH_INTERVAL } from "../../../App";

const sort = (a: IQuestion, b: IQuestion) =>
    +a.IsAnswered - +b.IsAnswered ||
    new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime();

const QuestionList: React.FC = (): React.ReactElement => {
    const [questions, setQuestions] = React.useState<IQuestion[]>([]);
    const [event, setEvent] = React.useState<IEvent | null>(null);
    const [attendCount, setAttendCount] = React.useState<number>(0);
    const { id: eventID } = useParams<{ id: string }>();

    const handleChange = (question: IQuestion) => {
        if (question.IsAnswered) {
            setQuestions(
                questions.map((q) =>
                    +q.QuestionID === +question.QuestionID
                        ? { ...question, IsPresenter: false }
                        : { ...q, IsPresenter: false }
                )
            );
        } else {
            setQuestions(
                questions.map((q) =>
                    +q.QuestionID === +question.QuestionID
                        ? question
                        : { ...q, IsPresenter: false }
                )
            );
        }
        QuestionService.updateQuestion(question);
    };

    const handleDeleteQuestion = (questionId: number) => {
        QuestionService.deleteQuestion(questionId)
            .then(() =>
                setQuestions(
                    questions.filter((q) => q.QuestionID !== questionId)
                )
            )
            .catch((err) =>
                console.log(
                    "[QUESTIONS/API] Delete Question Failed",
                    err.message
                )
            );
    };

    function getXlsx() {
        document.location.href = `/api/questions/xlsx/${eventID}`;
    }

    React.useEffect(() => {
        QuestionService.getQuestionsForEvent(+eventID)
            .then(setQuestions)
            .catch((err) =>
                console.log("[QUESTIONS/API] Get Question Failed", err.message)
            );
        EventService.getEvent(+eventID)
            .then(setEvent)
            .catch((err) =>
                console.log("[EVENT/API] Get Event Failed", err.message)
            );
        AttendService.getAttendanceCount(+eventID)
            .then(setAttendCount)
            .catch((err) =>
                console.log(
                    "[Attend/API] Get Attendance Count Failed",
                    err.message
                )
            );
    }, [eventID]);

    React.useEffect( () => {
        refreshData();
    }, []);

    async function refreshData() {
        const result = await QuestionService.getLiveEventQuestions();
        if (result) {
            setQuestions(result.questions);
            setAttendCount(result.count);
        }
    }

    useInterval(async () => {
        try {
            await refreshData();
        } catch (error) {
            console.log("[USEINTERVAL ERROR] Error in useInterval", error);
        }

    }, QUESTION_REFRESH_INTERVAL);

    return (
        <>
            <Header top={event?.Title || ""} bottom="Event Questions" />
            <div className="main-content">
                <div className="table-and-form__wrapper col-2-250">
                    <div className="table-and-form__table">
                        <Table
                            className="questions-table"
                            aria-label="simple table"
                            size="small"
                            padding="none"
                        >
                            <TableHead>
                                <TableRow className="table__head">
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell padding="default">
                                        Name
                                    </TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell padding="default">
                                        Question
                                    </TableCell>
                                    <TableCell align="center">
                                        Presenter
                                    </TableCell>
                                    <TableCell align="center" padding="default">
                                        Answered
                                    </TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questions.sort(sort).map((q, i) => (
                                    <TableRow
                                        key={q.QuestionID}
                                        style={{
                                            backgroundColor:
                                                i % 2 === 0
                                                    ? "#f6f5f7"
                                                    : "inherit",
                                        }}
                                    >
                                        <QuestionRow
                                            question={q}
                                            handleChange={handleChange}
                                            deleteQuestion={
                                                handleDeleteQuestion
                                            }
                                        />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <div className="table-and-form__form">
                        <div className="questions-utils__container">
                            <div className="questions-utils__buttons">
                                <p className="questions-utils__buttons-status">
                                    {questions.reduce(
                                        (p, c) => p + +c.IsAnswered,
                                        0
                                    )}{" "}
                                    out of {questions.length} questions answered
                                </p>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => refreshData()}
                                >
                                    Refresh Questions
                                </Button>
                                <Button
                                    style={{ marginTop: 4 }}
                                    color="primary"
                                    variant="contained"
                                    onClick={getXlsx}
                                >
                                    Export to Excel
                                </Button>
                            </div>
                            <div className="questions-utils__attendance">
                                <h6 className="questions-utils__attendance-header">
                                    Attendance
                                </h6>
                                <div className="questions-utils__attendance-total">
                                    <span>video.thehartford.com</span>
                                    <span>{attendCount}</span>
                                </div>
                                <div className="questions-utils__attendance-total">
                                    <span>Total</span>
                                    <span>{attendCount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuestionList;
