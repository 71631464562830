import React from "react";
import "./Presenter.css";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import Header from "../Header/Header";
import * as QuestionService from "../Core/Services/QuestionService";
import useInterval from "../Core/Hooks/useInterval";
import { IQuestion } from "../Core/types";
import { QUESTION_REFRESH_INTERVAL } from "../App";

const Presenter: React.FC = (props): React.ReactElement => {
    const [question, setQuestion] = React.useState<
        (IQuestion & { eventTitle: string }) | null
    >(null);

    useInterval(async () => {
        const question = await QuestionService.getLiveEventPresenterQuestion();
        setQuestion(question);
    }, QUESTION_REFRESH_INTERVAL);

    return (
        <div className="presenter__container">
            <Header top="" bottom={question?.eventTitle || ""} />
            <div className="presenter__question">
                <p>{question && question.Question}</p>
                <Divider />
                <p className="presenter__asked-by">
                    {question && `Asked by: ${question.Name || "anonymous"}`}
                </p>
            </div>
            <CircularProgress className="progress" color="primary" />
        </div>
    );
};

export default Presenter;
