import React from "react";
import { format } from "date-fns";
import { IEvent } from "../Core/types";

import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";

import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

interface EditRowProps {
    backgroundColor: string;
    event: IEvent;
    isEventTable: boolean;
    isReplayTable: boolean;
    isTemplateTable: boolean;
    onCancel: () => void;
    onSubmit: (event: IEvent, cb?: (confirm: boolean) => void) => void;
    markEventAsLive: (event: IEvent) => void;
}

const EditRow: React.FC<EditRowProps> = (props): React.ReactElement => {
    const [editEvent, setEditEvent] = React.useState<IEvent>(props.event);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditEvent({ ...editEvent, [e.target.name]: e.target.checked });
    };

    const handleCancel = () => {
        setEditEvent(props.event);
        props.onCancel();
    };

    const handleSubmit = () => {
        const shouldRevert = (confirmed: boolean) => {
            if (confirmed) {
                setEditEvent({ ...editEvent, IsActive: props.event.IsActive });
            }
        };
        props.onSubmit(editEvent, shouldRevert);
    };

    const handleEditIsActive = () => {
        setEditEvent({
            ...editEvent,
            IsActive: !editEvent.IsActive,
        });
    };

    return (
        <TableRow style={{ backgroundColor: props.backgroundColor }}>
            <TableCell>{props.event.EventID}</TableCell>
            <TableCell padding="default">
                <TextField
                    margin="dense"
                    size="small"
                    fullWidth
                    className="form-control block"
                    value={editEvent.Title}
                    helperText={
                        (editEvent.Title || "").length > 50 &&
                        "Must be 50 characters or less"
                    }
                    name="Title"
                    label="Title"
                    variant="outlined"
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    size="small"
                    fullWidth
                    className="form-control block"
                    value={editEvent.Subtitle}
                    name="Subtitle"
                    label="Subtitle"
                    variant="outlined"
                    onChange={handleInputChange}
                />
                <p className="white-space-nowrap">
                    Template:{" "}
                    <Checkbox
                        onChange={handleCheckboxChange}
                        name="IsTemplate"
                        checked={!!editEvent.IsTemplate}
                        color="primary"
                    />
                </p>
            </TableCell>
            <TableCell style={{ width: 250 }}>
                <TextField
                    fullWidth
                    name="DateOfEvent"
                    label="Event Date"
                    size="small"
                    margin="dense"
                    variant="outlined"
                    type="datetime-local"
                    className="form-control block date"
                    value={format(
                        new Date(editEvent.DateOfEvent || Date.now()),
                        "yyyy-MM-dd'T'HH:mm"
                    )}
                    onChange={handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </TableCell>

            <TableCell padding="default">
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control block"
                    onChange={handleInputChange}
                    fullWidth
                    name="AccessID"
                    type="text"
                    label="ID"
                    variant="outlined"
                    placeholder="Not Set"
                    value={editEvent.AccessID || ""}
                />
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control block"
                    onChange={handleInputChange}
                    fullWidth
                    name="AccessPWD"
                    type="text"
                    variant="outlined"
                    label="PWD"
                    placeholder="Not Set"
                    value={editEvent.AccessPWD || ""}
                />
            </TableCell>
            <TableCell padding="default">
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control block"
                    value={editEvent.AdditionalTitle || ""}
                    onChange={handleInputChange}
                    name="AdditionalTitle"
                    fullWidth
                    variant="outlined"
                    type="text"
                />
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control block"
                    value={editEvent.AdditionalText || ""}
                    multiline
                    onChange={handleInputChange}
                    name="AdditionalText"
                    variant="outlined"
                    type="text"
                />
            </TableCell>
            <TableCell>
                <div className="radio justify-content-between">
                    Questions:{" "}
                    <Checkbox
                        checked={!!editEvent.IsQuestions}
                        name="IsQuestions"
                        onChange={handleCheckboxChange}
                        color="primary"
                    />
                </div>

                <div className="radio justify-content-between">
                    Frame:{" "}
                    <Checkbox
                        checked={!!editEvent.IsFrame}
                        name="IsFrame"
                        onChange={handleCheckboxChange}
                        color="primary"
                    />
                </div>
            </TableCell>
            <TableCell align="center">
                {!!props.isTemplateTable || !!props.event.IsRebroadcast ? (
                    <Radio disabled />
                ) : (
                    <Checkbox
                        checked={!!editEvent.IsActive}
                        name="IsActive"
                        onChange={handleEditIsActive}
                        color="primary"
                    />
                )}
            </TableCell>
            <TableCell align="center">
                {!!props.isTemplateTable ? (
                    <Radio disabled />
                ) : (
                    <Checkbox
                        checked={!!editEvent.IsRebroadcast}
                        name="IsRebroadcast"
                        onChange={() =>
                            setEditEvent({
                                ...editEvent,
                                IsRebroadcast: !editEvent.IsRebroadcast,
                            })
                        }
                        color="primary"
                    />
                )}
            </TableCell>
            <TableCell
                padding="default"
                align="center"
                className="white-space-nowrap"
            >
                <Tooltip title="Save">
                    <IconButton
                        size="small"
                        style={{ marginRight: 12 }}
                        onClick={handleSubmit}
                    >
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton size="small" onClick={handleCancel}>
                        <UndoIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default EditRow;
