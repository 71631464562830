import React from "react";
import { AxiosError } from "axios";
import {
    IEvent,
    IEncoder,
    IEncoderDto,
    EncoderOperation,
} from "../../Core/types";

import * as EventService from "../../Core/Services/EventService";
import * as EncoderService from "../../Core/Services/EncoderService";

import Snackbar from "@material-ui/core/Snackbar";
import VideoEncoderForm from "../Components/VideoEncoderForm";
import VideoEncoderList from "../Components/VideoEncoderList";
import EncoderStopDialog from "../Components/EncoderStopDialog";
import EncoderTestDialog from "../Components/EncoderTestDialog";
import EncoderStartDialog from "../Components/EncoderStartDialog";

const VideoEncoderContainer: React.FC = (): React.ReactElement => {
    const [events, setEvents] = React.useState<IEvent[]>([]);
    const [encoders, setEncoders] = React.useState<IEncoder[]>([]);
    const [eventID, setEventID] = React.useState<number>(0);
    const [encoderID, setEncoderID] = React.useState<number>(0);
    const [confirmStop, setConfirmStop] = React.useState<number>(0);
    const [testDialogOpen, setTestDialogOpen] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const handleSubmitEdit = (editedEncoder: IEncoder) => {
        EncoderService.updateEncoder(editedEncoder)
            .then((encoder) =>
                setEncoders(
                    encoders.map((e) =>
                        e.EncoderID === encoder.EncoderID ? encoder : e
                    )
                )
            )
            .catch((err) => {
                console.log(
                    "[ENCODER/API] Updated Encoder Failed",
                    err.message
                );
            });
    };

    const handleSubmitNew = (newEncoder: IEncoderDto) => {
        return EncoderService.createEncoder(newEncoder)
            .then((encoder) => {
                setEncoders([...encoders, encoder]);
                return true;
            })
            .catch((err: AxiosError) => {
                console.log(err);
                let message =
                    err.response?.data?.message || "Unable to create encoder";
                setErrorMessage(message);
                console.log(
                    "[ENCODER/API] Create Encoder Failed",
                    (err as AxiosError).response!.data.message
                );
                return false;
            });
    };

    const handleDeleteEncoder = (id: number) => {
        EncoderService.deleteEncoder(id)
            .then(() => setEncoders(encoders.filter((r) => r.EncoderID !== id)))
            .catch((err) =>
                console.log(
                    "[ENCODER/API] Delete Encoder Failed",
                    (err as AxiosError).response!.data.message
                )
            );
        setTestDialogOpen(false);
    };

    const handleToggleEncoder = (encoder: IEncoder, status: string) => {
        switch (status) {
            case "Started":
                setConfirmStop(encoder.EncoderID);
                break;
            case "Stopped":
            case "Error":
                setEncoderID(encoder.EncoderID);
                break;
            default:
                setErrorMessage("Could not get the encoder status");
        }
    };

    const handleConfirmEvent = () => {
        if (eventID !== 0 && encoderID !== 0) {
            const enId = encoderID;
            EncoderService.startEncoder(encoderID, eventID)
                .then(({ Status }) => {
                    setEncoders(
                        encoders.map((e) =>
                            e.EncoderID === enId ? { ...e, Status } : e
                        )
                    );
                })
                .catch((err) => {
                    // TODO: re-open dialog
                    console.log(
                        (err as AxiosError).response!.data.message.message
                    );
                    setErrorMessage(
                        "Could not start encoder\n" +
                            (err as AxiosError).response!.data.message.message
                    );
                });
        }
        setEventID(0);
        setEncoderID(0);
    };

    const handleConfirmYes = () => {
        const id = confirmStop;
        if (confirmStop) {
            EncoderService.stopEncoder(confirmStop)
                .then(({ Status }) => {
                    setEncoders(
                        encoders.map((e) =>
                            e.EncoderID === id ? { ...e, Status } : e
                        )
                    );
                })
                .catch((err) =>
                    console.log(
                        "[Encoder Service] error when stopping the encoder",
                        (err as AxiosError).response!.data.message
                    )
                );
        }
        setConfirmStop(0);
    };
    const handleConfirmNo = () => {
        setConfirmStop(0);
    };

    React.useEffect(() => {
        EncoderService.getEncoders()
            .then(setEncoders)
            .catch((err) =>
                console.log(
                    "[ENCODER/API] ERROR could not fetch encoders",
                    (err as AxiosError).response!.data.message
                )
            );
        EventService.getEvents()
            .then(setEvents)
            .catch((err) =>
                console.log(
                    "[EVENT/API] ERROR could not fetch events",
                    (err as AxiosError).response!.data.message
                )
            );
    }, [setEncoders, setEvents]);
    const handleCloseTestDialog = () => {
        setTestDialogOpen(false);
    };
    const handleSelectEncoderToTest = (encoder: IEncoder) => {
        EncoderService.getTestVivohProtocol(encoder)
            .then(({ protocol }) => {
                if (protocol) {
                    console.log(
                        "NEEDS IMPLEMENTATION. open vivoh protocol",
                        protocol
                    );
                    const meta = document.createElement("meta");
                    meta.setAttribute("http-equiv", "refresh");
                    meta.setAttribute("content", `0;URL='${protocol}'`);
                    document.head.appendChild(meta);
                }
            })
            .catch((err) =>
                console.log(
                    "[SOURCE/API] Could not retrieve source for encoder #" +
                        encoder.EncoderID,
                    err
                )
            )
            .finally(() => setTestDialogOpen(false));
    };
    return (
        <div className="table-and-form__wrapper">
            {confirmStop > 0 && (
                <EncoderStopDialog
                    handleConfirmNo={handleConfirmNo}
                    handleConfirmYes={handleConfirmYes}
                />
            )}
            {encoderID !== 0 && (
                <EncoderStartDialog
                    events={events}
                    eventID={eventID}
                    setEventID={setEventID}
                    handleConfirmNo={handleConfirmNo}
                    handleConfirmEvent={handleConfirmEvent}
                />
            )}
            {testDialogOpen && (
                <EncoderTestDialog
                    encoders={encoders.filter((e) => {
                        console.log(e);
                        return e.Status === EncoderOperation.STARTED;
                    })}
                    handleClose={handleCloseTestDialog}
                    testEncoder={handleSelectEncoderToTest}
                />
            )}
            <div className="table-and-form__table">
                <VideoEncoderList
                    encoders={encoders}
                    submit={handleSubmitEdit}
                    setErrorMessage={setErrorMessage}
                    handleTestEncoder={handleSelectEncoderToTest}
                    toggleEncoder={handleToggleEncoder}
                    deleteVideoEncoder={handleDeleteEncoder}
                />
            </div>
            <div className="table-and-form__form width-300">
                <VideoEncoderForm submit={handleSubmitNew} />
            </div>

            <Snackbar
                open={Boolean(errorMessage)}
                onClose={() => setErrorMessage(null)}
                
                message={errorMessage}
                autoHideDuration={6000}
            />
        </div>
    );
};

export default VideoEncoderContainer;
