import React from "react";
import { IFrameDto } from "../../../Core/types";
import TextField from "@material-ui/core/TextField";
import { Button, Typography } from "@material-ui/core";
import "./Frame.css";
interface FrameFormProps {
    submit: (frame: IFrameDto) => void;
    eventId: number;
}
const initialFrame = (EventID: number): IFrameDto => ({
    EventID,
    Name: "",
    URL: "",
});
const FrameForm: React.FC<FrameFormProps> = ({
    submit,
    eventId
}): React.ReactElement => {
    const [newFrame, setNewFrame] = React.useState<IFrameDto>(initialFrame(eventId));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewFrame({ ...newFrame, [e.target.name]: e.target.value });
    };
    const handleSubmit = () => {
        if (newFrame.Name.trim() && newFrame.URL.trim()) {
            submit(newFrame);
            setNewFrame(initialFrame(eventId));
        }
    };
    return (
        <div className="form-container width-300">
            <Typography variant="h6" className="form__header">
                Create a New Frame
            </Typography>
            <div className="form">
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control"
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    name="Name"
                    label="Name"
                    value={newFrame.Name}
                />

                <TextField
                    margin="dense"
                    size="small"
                    className="form-control"
                    onChange={handleChange}
                    variant="outlined"
                    type="text"
                    multiline
                    rows={3}
                    name="URL"
                    label="URL"
                    value={newFrame.URL}
                />
            </div>

            <Button
                color="primary"
                variant="contained"
                className="submit-btn"
                onClick={handleSubmit}
            >
                Create New Frame
            </Button>
        </div>
    );
};

export default FrameForm;
