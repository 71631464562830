import axios, { AxiosError } from "axios";
import { processEncoderDataForResponse } from "./GenerateEncoderDescription";
import {
    IEncoder,
    IEncoderDto,
    EncoderResponse,
    EncoderOperation,
    EncoderStatusResponse,
} from "../types";
export const deleteEncoder = (id: number): Promise<IEncoder> => {
    return axios
        .delete<{ data: IEncoder }>(`/api/encoders/${id}`)
        .then((r) => r.data.data);
};

export const createEncoder = (Encoder: IEncoderDto): Promise<IEncoder> => {
    return axios
        .post<{ data: IEncoder }>("/api/encoders", Encoder)
        .then((r) => r.data.data);
};

export const getEncoders = (): Promise<IEncoder[]> => {
    return axios
        .get<{ data: IEncoder[] }>(`/api/encoders`)
        .then((r) => r.data.data);
};

export const updateEncoder = (Encoder: IEncoder): Promise<IEncoder> => {
    return axios
        .put<{ data: IEncoder }>(`/api/encoders/${Encoder.EncoderID}`, Encoder)
        .then((r) => {
            console.log(r.data);
            return r.data.data
        });
};

export const getStatus = async (
    Encoder: IEncoder
): Promise<EncoderResponse> => {
    try {
        const r = await axios.get<EncoderStatusResponse>(
            `/api/encoders/${Encoder.EncoderID}`
        );
        console.log(r.data);

        return processEncoderDataForResponse(r.data);
    } catch (err) {
        const { message } = (err as AxiosError).response!.data;
        let fullMessage = `HTTP Status: ERROR (Encoder ID: ${Encoder.EncoderID})`;
        if (message) {
            fullMessage += ` (${message})`;
        }
        return {
            Status: EncoderOperation.ERROR,
            descriptive: fullMessage,
            status: "Error",
        };
    }
};

type EncoderToggleResponse = { message: string; Status: EncoderOperation };

export const startEncoder = (
    EncoderID: number,
    EventID: number
): Promise<EncoderToggleResponse> => {
    return axios
        .put<EncoderToggleResponse>(`/api/encoders/start/${EncoderID}`, {
            EventID,
        })
        .then((r) => r.data);
};

export const stopEncoder = (
    EncoderID: number
): Promise<EncoderToggleResponse> => {
    return axios
        .put<EncoderToggleResponse>(`/api/encoders/stop/${EncoderID}`)
        .then((r) => r.data);
};

export const getTestVivohProtocol = (
    encoder: IEncoder
): Promise<{ protocol: string }> => {
    return axios
        .get<{ protocol: string }>(
            `/api/encoders/${encoder.EncoderID}/test-protocol`
        )
        .then((r) => r.data);
};
