import { Button, TextField } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import { IEvent, ISource } from "../../../Core/types";
import * as SourceService from "../../../Core/Services/SourceService";
import * as EventService from "../../../Core/Services/EventService";
import Header from "../../../Header/Header";
interface VideoStreamFormProps {}

const VideoStreamForm: React.FC<VideoStreamFormProps> = (): React.ReactElement => {
    const { id: EventID } = useParams<{ id: string }>();
    const [form, setForm] = React.useState<ISource | null>(null);
    const [event, setEvent] = React.useState<IEvent | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (form) {
            setForm({ ...form, [e.target.name]: e.target.value });
        }
    };

    const handleUpdate = () => {
        if (form) {
            SourceService.updateSource(form)
                .then(setForm)
                .catch((err) =>
                    console.log("[SOURCE/API] Update Source Failed", err.message)
                );
        }
    };

    React.useEffect(() => {
        SourceService.getSource(+EventID)
            .then(setForm)
            .catch((err) => console.log("[SOURCE/API] Get Source Failed", err.message));
        EventService.getEvent(+EventID)
            .then(setEvent)
            .catch((err) => console.log("[EVENT/API] Get Event Failed", err.message));
    }, [setForm, EventID]);

    return (
        <>
            <Header top={event?.Title || ""} bottom="Video Streams" />
            <div className="main-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        paddingTop: 8,
                        paddingLeft: 8,
                        width: "50%",
                    }}
                >
                    {form && (
                        <>
                            <TextField
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                name="PrimaryMulticastUrl"
                                label="Primary Multicast Url"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                value={form.PrimaryMulticastUrl || ""}
                            />
                            <TextField
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                name="FailoverMulticastUrl"
                                label="Failover Multicast Url"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                value={form.FailoverMulticastUrl || ""}
                            />

                            <TextField
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                name="PrimaryRTMPSource"
                                label="Primary RTMP Source"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                value={form.PrimaryRTMPSource || ""}
                            />

                            <TextField
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                name="FailoverRTMPSource"
                                label="Failover RTMP Source"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                value={form.FailoverRTMPSource || ""}
                            />

                            <TextField
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                name="PrimaryUnicastUrl"
                                label="Unicast Url for Wireless"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                value={form.PrimaryUnicastUrl || ""}
                            />

                            <TextField
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                name="SecondaryUnicastUrl"
                                label="Unicast URL for Remote"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                value={form.SecondaryUnicastUrl || ""}
                            />

                            <TextField
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                name="ReplayUrl"
                                label="Replay Url"
                                variant="outlined"
                                style={{ marginBottom: 8 }}
                                value={form.ReplayUrl || ""}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleUpdate}
                            >
                                Update
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default VideoStreamForm;
