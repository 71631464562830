import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { ILink } from "../../../Core/types";
import LinksEdit from "./LinksEdit";
import LinksRow from "./LinksRow";

interface LinksListProps {
    links: ILink[];
    submitLink: (link: ILink) => void;
    deleteLink: (id: number) => void;
}

const LinksList: React.FC<LinksListProps> = ({
    links,
    submitLink,
    deleteLink,
}): React.ReactElement => {
    const [selectedLink, setSelectedLink] = React.useState<ILink | null>(null);

    const handleSubmit = (link: ILink) => {
        submitLink(link);
        setSelectedLink(null);
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow className="table__head">
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Url</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {links.map((link, index) => {
                        const bg = index % 2 === 0 ? "#f6f5f7" : "inherit";
                        return selectedLink?.LinkID === link.LinkID ? (
                            <LinksEdit
                                cancel={() => setSelectedLink(null)}
                                backgroundColor={bg}
                                key={link.LinkID}
                                link={link}
                                submit={handleSubmit}
                            />
                        ) : (
                            <LinksRow
                                key={link.LinkID}
                                backgroundColor={bg}
                                link={link}
                                deleteLink={deleteLink}
                                selectLink={setSelectedLink}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LinksList;
