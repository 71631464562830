import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { IFrame } from "../../../Core/types";

interface FrameRowProps {
    backgroundColor: string;
    frame: IFrame;
    selectFrame: (frame: IFrame) => void;
    deleteFrame: (id: number) => void;
    toggleActive: (id: number) => void;
}

const FrameRow: React.FC<FrameRowProps> = ({
    frame,
    selectFrame,
    deleteFrame,
    toggleActive,
    backgroundColor,
}): React.ReactElement => {
    return (
        <TableRow style={{ backgroundColor }}>
            <TableCell align="center" style={{ padding: "0 4px" }}>
                {frame.FrameID}
            </TableCell>
            <TableCell align="center">{frame.Name}</TableCell>

            <TableCell align="center">{frame.URL}</TableCell>
            <TableCell align="center">
                <Checkbox
                    checked={!!frame.IsActive}
                    color="primary"
                    onClick={() => toggleActive(frame.FrameID)}
                />
            </TableCell>
            <TableCell align="right">
                <p className="white-space-nowrap">
                    <Tooltip title="Edit">
                        <IconButton
                            style={{ marginRight: 4 }}
                            size="small"
                            onClick={() => selectFrame(frame)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            size="small"
                            onClick={() => deleteFrame(frame.FrameID)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </p>
            </TableCell>
        </TableRow>
    );
};

export default FrameRow;
