import axios from "axios";
import { IAttend } from "../types";

export const deleteAttend = (id: number): Promise<IAttend> => {
    return axios
        .delete<{ data: IAttend }>(`/api/attends/${id}`)
        .then((r) => r.data.data);
};

export const createAttend = (Attend: IAttend): Promise<IAttend> => {
    return axios
        .post<{ data: IAttend }>("/api/attends", Attend)
        .then((r) => r.data.data);
};

export const getAttendsForEvent = 
    (EventID: number, page: number = 0): Promise<{ count: number, attendees: IAttend[] }> => {
    return axios
        .get<{ data: { count: number, attendees: IAttend[] } }>(`/api/attends/${EventID}?page=${page}`)
        .then((r) => r.data.data);
};

// export const getCsvAttendeesForEvent = 
//     (EventID: number): Promise<> => {
//     return axios
//         .get(`/api/attends/csv/${EventID}`);
// };

export const updateAttend = (Attend: IAttend): Promise<IAttend> => {
    return axios
        .put<{ data: IAttend }>(`/api/attends/${Attend.AttendID}`, Attend)
        .then((r) => r.data.data);
};

export const getAttendanceCount = (EventID: number): Promise<number> => {
    return axios
        .get<{ count: number }>(`/api/attends/${EventID}/count`)
        .then((r) => r.data.count);
};

export const getAttendanceCountForLiveEvent = (): Promise<number> => {
    return axios
        .get<{ count: number }>(`/api/attends/live/count`)
        .then((r) => r.data.count);
};
