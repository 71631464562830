import React from "react";

import { IFrame } from "../../../Core/types";
import Checkbox from "@material-ui/core/Checkbox";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

interface FrameEditProps {
    backgroundColor: string;
    frame: IFrame;
    cancel: () => void;
    submit: (frame: IFrame) => void;
}

const FrameEdit: React.FC<FrameEditProps> = ({
    frame,
    submit,
    cancel,
    backgroundColor,
}): React.ReactElement => {
    const [editFrame, setEditFrame] = React.useState<IFrame>(frame);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditFrame({ ...editFrame, [e.target.name]: e.target.value });
    };

    return (
        <TableRow style={{ backgroundColor }}>
            <TableCell align="center">{editFrame.FrameID}</TableCell>
            <TableCell align="center">
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control"
                    onChange={handleChange}
                    name="Name"
                    type="text"
                    variant="outlined"
                    label="Name"
                    value={editFrame.Name}
                />
            </TableCell>

            <TableCell align="center">
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control"
                    onChange={handleChange}
                    name="URL"
                    type="text"
                    variant="outlined"
                    label="URL"
                    value={editFrame.URL}
                />
            </TableCell>
            <TableCell align="center">
                <Checkbox
                    checked={!!editFrame.IsActive}
                    color="primary"
                    onChange={() =>
                        setEditFrame({
                            ...editFrame,
                            IsActive: !editFrame.IsActive,
                        })
                    }
                />
            </TableCell>
            <TableCell align="right">
                <Tooltip title="Save">
                    <IconButton
                        style={{ marginRight: 4 }}
                        size="small"
                        onClick={() => submit(editFrame)}
                    >
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton size="small" onClick={() => cancel()}>
                        <UndoIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default FrameEdit;
