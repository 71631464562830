import React, { useEffect } from "react";
import { IQuestion } from "../Core/types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

import * as QuestionService from "../Core/Services/QuestionService";

import useInterval from "../Core/Hooks/useInterval";
import { Redirect } from "react-router";
import { QUESTION_REFRESH_INTERVAL } from "../App";

const markAsAnswered = (updatedQuestion: IQuestion) => (
    q: IQuestion
): IQuestion =>
    updatedQuestion.QuestionID === q.QuestionID
        ? { ...updatedQuestion, IsAnswered: true, IsPresenter: false }
        : q;

const sort = (a: IQuestion, b: IQuestion) =>
    +a.IsAnswered - +b.IsAnswered ||
    new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime();

const markAsPresenter = (updatedQuestion: IQuestion) => (
    q: IQuestion
): IQuestion =>
    updatedQuestion.QuestionID === q.QuestionID
        ? { ...updatedQuestion, IsAnswered: false, IsPresenter: true }
        : { ...q, IsPresenter: false };

const Moderator: React.FC = (): React.ReactElement => {
    const [questions, setQuestions] = React.useState<IQuestion[] | null>([]);
    const [attendanceCount, setCount] = React.useState<number>(0);

    const handleChange = (question: IQuestion) => {
        if (questions) {
            const oldQuestions = [...questions];
            const oldQuestion = questions.find(
                (q) => q.QuestionID === question.QuestionID
            );
            if (!question.IsAnswered && oldQuestion?.IsAnswered) {
                setQuestions(
                    questions
                        .map((q) =>
                            q.QuestionID === question.QuestionID
                                ? { ...question, IsAnswered: false }
                                : q
                        )
                        .sort(sort)
                );
            } else if (question.IsAnswered && question.IsPresenter) {
                if (oldQuestion) {
                    if (!oldQuestion.IsAnswered) {
                        // setQuestions(questions.map(markAsAnswered(question)));
                        setQuestions((qs) => {
                            return (qs || [])
                                .map(markAsAnswered(question))
                                .sort(sort);
                        });
                    }
                }
            } else if (question.IsAnswered && !question.IsPresenter) {
                // setQuestions(questions.map(markAsAnswered(question)));
                setQuestions((qs) => {
                    return (qs || []).map(markAsAnswered(question)).sort(sort);
                });
            } else if (!question.IsAnswered && question.IsPresenter) {
                // setQuestions(questions.map(markAsPresenter(question)));
                setQuestions((qs) => {
                    return (qs || []).map(markAsPresenter(question)).sort(sort);
                });
            }
            QuestionService.updateQuestion(question)
                .then((q) => {
                    setQuestions((qs) => {
                        return (qs || [])
                            .map((qu) =>
                                qu.QuestionID === q.QuestionID ? q : qu
                            )
                            .sort(sort);
                    });
                })
                .catch((err) => {
                    console.log("[QUESTION/API] Update questionFailed!", err);
                    setQuestions(oldQuestions);
                });
        }
    };

    async function refreshData() {
        const result = await QuestionService.getLiveEventQuestions();
        if (result) {
            setQuestions(result.questions.sort(sort));
            setCount(result.count);
        } else {
            setQuestions(null);
        }
    }

    useInterval(async () => {
        try {
            await refreshData();
        } catch (error) {
            console.log("[USEINTERVAL ERROR] Error in useInterval", error);
        }

    }, QUESTION_REFRESH_INTERVAL);

    useEffect( () => {
        refreshData();
    }, []);

    return questions ? (
        <div className="moderator__container">
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow className="table__head">
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Question</TableCell>
                            <TableCell align="center">Presenter</TableCell>
                            <TableCell align="center">Answered</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((q, i) => (
                            <TableRow
                                style={{
                                    backgroundColor:
                                        i % 2 === 0 ? "#f6f5f7" : "inherit",
                                }}
                                key={`${q.QuestionID}_${i}`}
                            >
                                <TableCell align="center">
                                    {q.QuestionID}
                                </TableCell>
                                <TableCell align="center">{q.Name}</TableCell>
                                <TableCell align="center">{q.Email}</TableCell>
                                <TableCell style={{ wordBreak: "break-word" }}>
                                    {q.Question}
                                </TableCell>

                                <TableCell align="center">
                                    <Checkbox
                                        checked={!!q.IsPresenter}
                                        color="primary"
                                        disabled={!!q.IsAnswered}
                                        onClick={() => {
                                            handleChange({
                                                ...q,
                                                IsPresenter: !q.IsPresenter,
                                            });
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <Checkbox
                                        checked={!!q.IsAnswered}
                                        color="primary"
                                        onClick={() => {
                                            handleChange({
                                                ...q,
                                                IsAnswered: !q.IsAnswered,
                                            });
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div
                className="questions-utils__container"
                style={{ marginLeft: 10 }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    style={{ marginBottom: 16 }}
                    onClick={() => refreshData()}
                >
                    Refresh Questions
                </Button>

                <div className="questions-utils__attendance">
                    <h6 className="questions-utils__attendance-header">
                        Attendance
                    </h6>
                    <div className="questions-utils__attendance-total">
                        <span>video.thehartford.com</span>
                        <span>{attendanceCount}</span>
                    </div>
                    <div className="questions-utils__attendance-total">
                        <span>Total</span>
                        <span>{attendanceCount}</span>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <Redirect to="/player" />
    );
};

export default Moderator;
