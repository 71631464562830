import React from "react";
import "../../Events.css";
import Snackbar from "@material-ui/core/Snackbar";

import { IEvent } from "../../../Core/types";

import Header from "../../../Header/Header";
import EventTable from "../../../Shared/EventTable";
import WarningDialog from "../../../Shared/Dialogs/CustomDialog";

import * as EventService from "../../../Core/Services/EventService";

type DialogState = {
    handleClose: (() => void) | ((yes: boolean) => void);
    type: "confirm" | "info";
    title: string;
};

const EventList: React.FC = (): React.ReactElement => {
    const [events, setEvents] = React.useState<IEvent[]>([]);
    const [dialogState, setDialogState] = React.useState<DialogState | false>(
        false
    );
    const [errorMessage, setErrorMessage] = React.useState<string>();

    const storeErrors = (...errors: string[]) => {
        const error = errors.join(" ");
        setErrorMessage(error);
    };

    const confirmationDialog = (): Promise<boolean> => {
        return new Promise((resolve) => {
            setDialogState({
                title: "Stop current live event?",
                handleClose: (confirmation: boolean) => {
                    resolve(confirmation);
                    setDialogState(false);
                },
                type: "confirm",
            });
        });
    };

    const handleUpdateEvent = async (
        event: IEvent
    ): Promise<{ errors: any } | null> => {
        return new Promise((resolve, reject) => {
            if (event.IsActive) {
                if (event.IsTemplate || event.IsRebroadcast) {
                    confirmationDialog().then((confirmed) => {
                        if (confirmed) {
                            handleUpdate({ ...event, IsActive: false })
                                .then(resolve)
                                .catch(reject);
                        } else resolve({ errors: "declined" });
                    });
                } else {
                    const activeEvent = checkForActiveEvents();
                    if (activeEvent && activeEvent.EventID !== event.EventID) {
                        confirmationDialog().then((confirmed) => {
                            if (confirmed) {
                                handleUpdate({
                                    ...activeEvent,
                                    IsActive: false,
                                });
                                handleUpdate(event).then(resolve).catch(reject);
                            }
                        });
                    } else {
                        handleUpdate(event).then(resolve).catch(reject);
                    }
                }
            } else {
                const originalEvent = events.find(
                    (e) => e.EventID === event.EventID
                );
                if (originalEvent && originalEvent.IsActive) {
                    confirmationDialog().then((confirmed) => {
                        if (confirmed) {
                            handleUpdate(event).then(resolve).catch(reject);
                        } else resolve({ errors: "declined" });
                    });
                } else {
                    handleUpdate(event).then(resolve).catch(reject);
                }
            }
        });
    };

    const handleUpdate = (event: IEvent) => {
        return EventService.updateEvent(event)
            .then((updatedEvent) => {
                if (updatedEvent.IsActive) {
                    setEvents(
                        events.map((e) =>
                            e.EventID === updatedEvent.EventID
                                ? updatedEvent
                                : { ...e, IsActive: false }
                        )
                    );
                } else if (
                    updatedEvent.IsTemplate ||
                    updatedEvent.IsRebroadcast
                ) {
                    setEvents(
                        events.filter((e) => e.EventID !== updatedEvent.EventID)
                    );
                } else {
                    setEvents(
                        events.map((e) =>
                            e.EventID === updatedEvent.EventID
                                ? updatedEvent
                                : e
                        )
                    );
                }
                return null;
            })
            .catch((err) => {
                storeErrors("[EVENT/API] Update event failed", err.toString());
                return { errors: err };
            });
    };

    const deleteEvent = (id: number) => {
        setDialogState(false);
        EventService.deleteEvent(id)
            .then(() => setEvents(events.filter((e) => e.EventID !== id)))
            .catch((err) =>
                storeErrors("[ERROR] Event couldn't be deleted", err.message)
            );
    };

    const handleDuplicate = (e: IEvent) => {
        EventService.duplicateEvent(e)
            .then((newEvent) => setEvents([newEvent, ...events]))
            .catch((err) =>
                storeErrors("[ERROR] Event couldn't be duplicated", err.message)
            );
    };

    const handleDelete = (event: IEvent) => {
        if (events.length <= 1) {
            setDialogState({
                type: "info",
                handleClose: () => {
                    setDialogState(false);
                },
                title: "Cannot delete last event",
            });
        } else {
            setDialogState({
                handleClose: (confirmation: boolean) => {
                    if (confirmation) {
                        deleteEvent(event.EventID);
                    }
                    setDialogState(false);
                },
                title: "Delete Event?",
                type: "confirm",
            });
        }
    };

    const setEventIsLiveToTrue = (e: IEvent) => {
        EventService.updateEvent({
            ...e,
            IsActive: true,
            IsRebroadcast: false,
        })
            .then((updatedEvent) => {
                const newEvents = events.map((ev) =>
                    ev.EventID === e.EventID
                        ? updatedEvent
                        : ({ ...ev, IsActive: false } as IEvent)
                );
                setEvents(newEvents);
            })
            .catch((err) =>
                storeErrors("[EVENT/API] Update Event Failed", err.message)
            );
    };
    const setEventIsLiveToFalse = (e: IEvent) => {
        EventService.updateEvent({ ...e, IsActive: false })
            .then((updatedEvent) => {
                const newEvents = events.map((ev) =>
                    ev.EventID === updatedEvent.EventID ? updatedEvent : ev
                );
                setEvents(newEvents);
            })
            .catch((err) =>
                storeErrors("[EVENT/API] Update Event Failed", err.message)
            );
    };

    const handleMarkRebroadcast = (e: IEvent) => {
        if (e.IsActive) {
            setDialogState({
                title: "Stop current live event?",
                handleClose: (yes: boolean) => {
                    if (yes) {
                        setEventIsLiveToFalse(e);
                        handleUpdate({
                            ...e,
                            IsActive: false,
                            IsRebroadcast: true,
                        });
                    }
                    setDialogState(false);
                },
                type: "confirm",
            });
        } else {
            handleUpdate({ ...e, IsActive: false, IsRebroadcast: true });
        }
    };

    const isSameEvent = (e1: IEvent, e2: IEvent) => {
        return e1 && e2 && e1.EventID === e2.EventID;
    };

    const checkForActiveEvents = (): IEvent | false => {
        const activeEvents = events.filter((e) => e.IsActive && !e.IsTemplate);

        if (activeEvents.length > 1) {
            throw new Error(
                "[FATAL ERROR] There are multiple events marked as live"
            );
        }
        if (activeEvents.length) {
            return activeEvents[0];
        } else return false;
    };

    const handleMarkActive = (e: IEvent) => {
        const activeEvent = checkForActiveEvents();
        // the event will come to us NOT marked as active. So there is no
        // point in checking e.IsActive
        if (activeEvent) {
            if (!isSameEvent(e, activeEvent)) {
                // if the active event is the same as the event being edited
                setDialogState({
                    title: "Stop current live event?",
                    handleClose: (yes: boolean) => {
                        if (yes) {
                            setEventIsLiveToFalse(activeEvent);
                            setEventIsLiveToTrue(e);
                        }
                        setDialogState(false);
                    },
                    type: "confirm",
                });
            }
        } else {
            setEventIsLiveToTrue(e);
        }
    };
    const handleMarkAsTemplate = (event: IEvent) => {
        console.log("NOT IMPLEMENTED YET", event);
    };

    React.useEffect(() => {
        EventService.getEvents().then(setEvents);
    }, []);

    return (
        <>
            <Header top="Video Streaming" bottom="Event List" />
            <div className="main-content">
                {dialogState && <WarningDialog {...dialogState} />}

                <EventTable
                    events={events}
                    isEventTable={true}
                    isReplayTable={false}
                    isTemplateTable={false}
                    onDelete={handleDelete}
                    onDuplicate={handleDuplicate}
                    onMarkActive={handleMarkActive}
                    onEditSubmit={handleUpdateEvent}
                    onMarkAsTemplate={handleMarkAsTemplate}
                    onMarkRebroadcast={handleMarkRebroadcast}
                />
            </div>

            <Snackbar
                open={errorMessage !== undefined}
                onClose={() => setErrorMessage(undefined)}
                message={errorMessage}
                autoHideDuration={6000}
            />
        </>
    );
};

export default EventList;
