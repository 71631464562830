import React from "react";
import { IEncoderDto } from "../../Core/types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
interface VideoEncoderFormProps {
    submit: (encoder: IEncoderDto) => Promise<boolean>;
}

const VideoEncoderForm: React.FC<VideoEncoderFormProps> = ({
    submit,
}): React.ReactElement => {
    const [newEncoder, setNewEncoder] = React.useState<IEncoderDto>({
        URL: "",
        Name: "",
        Username: "",
        Password: "",
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setNewEncoder({ ...newEncoder, [e.target.name]: e.target.value });
    };

    const onSubmit = () => {
        if (
            newEncoder.URL &&
            newEncoder.URL.trim() &&
            newEncoder.Name &&
            newEncoder.Name.trim()
        ) {
            submit(newEncoder).then((success) => {
                if (success) {
                    console.log("Successfully Added encoder");
                    setNewEncoder({
                        URL: "",
                        Name: "",
                        Username: "",
                        Password: "",
                    });
                }
            });
        }
    };
    return (
        <div className="form-container">
            <Typography
                variant="h4"
                className="form__header"
                style={{ margin: 0, padding: "4px 0" }}
            >
                New Video Encoder
            </Typography>
            <div className="form">
                <TextField
                    name="Name"
                    onChange={handleChange}
                    value={newEncoder.Name}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Name"
                />
                <TextField
                    name="URL"
                    onChange={handleChange}
                    value={newEncoder.URL}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Encoder URL"
                />
                <TextField
                    name="Username"
                    onChange={handleChange}
                    value={newEncoder.Username}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Username"
                />
                <TextField
                    name="Password"
                    onChange={handleChange}
                    value={newEncoder.Password}
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Password"
                />
            </div>
            <Button onClick={onSubmit} variant="contained" color="primary">
                Submit
            </Button>
        </div>
    );
};

export default VideoEncoderForm;
