import React from "react";
import "./App.css";
import { Route, Switch } from "react-router";
import MainRouter from "./Routers/MainRouter";
import { makeStyles } from "@material-ui/core";
import Widget from "./Player/Components/Widget";
import useUsername from "./Core/Hooks/useUsername";

import Header from "./Header/Header";
import CircularProgress from "@material-ui/core/CircularProgress";

export const QUESTION_REFRESH_INTERVAL = 30 * 1000;

const MainApp = () => {
    return (
        <div className="App">
            <MainRouter />
        </div>
    );
};
const useStyles = makeStyles((theme) => ({
    progressContainer: {
        display: "flex",
        marginTop: "4rem",
        flexDirection: "column",
        alignItems: "center",
    },
    progress: {
        color: theme.palette.primary.main,
        animationDuration: "550ms",
        marginTop: 12,
    },
    circle: {
        strokeLinecap: "round",
    },
}));
function App() {
    const classes = useStyles();
    const username = useUsername();

    if (username)
        return (
            <Switch>
                <Route path="/widget" component={Widget} />
                <Route component={MainApp} />
            </Switch>
        );
    else
        return (
            <div className="no-event__wrapper">
                <div style={{ width: 350, textAlign: "center" }}>
                    <Header top="" bottom="" />
                    <div className="no-event__box">
                        <h1
                            style={{
                                textDecoration: "none",
                                margin: "4px 0",
                            }}
                            className="text-center"
                        >
                            Please Wait
                        </h1>
                    </div>
                    <div className="no-event__box">
                        <CircularProgress
                            size={50}
                            disableShrink
                            className={classes.progress}
                            classes={{ circle: classes.circle }}
                            thickness={5}
                            variant="indeterminate"
                            color="primary"
                        />
                    </div>
                </div>
            </div>
        );
}

export default App;
