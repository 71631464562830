import React from "react";
import cidrRegex from "cidr-regex";
import { IpRange } from "../../Core/types";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";
import SaveIcon from "@material-ui/icons/Save";

interface IpRangeEditProps {
    backgroundColor: string;
    ipRange: IpRange;
    cancel: () => void;
    onSubmitEdit: (ipRange: IpRange) => void;
}
interface IPRangeErrors {
    IPv4?: string | null;
    Name?: string | null;
}
const IpRangeEdit: React.FC<IpRangeEditProps> = ({
    ipRange,
    cancel,
    onSubmitEdit,
    backgroundColor
}): React.ReactElement => {
    const [editIpRange, setEditIpRange] = React.useState<IpRange>(ipRange);
    const [errors, setErrors] = React.useState<IPRangeErrors>({});
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditIpRange({ ...editIpRange, [e.target.name]: e.target.value });
    };

    const handleSubmitEdit = () => {
        const hasErrors = validate();
        if (!hasErrors) {
            onSubmitEdit(editIpRange);
        }
    };
    const validateIPv4 = (): string | null => {
        if (editIpRange.IPv4.trim().length < 1)
            return "Ip Range cannot be blank";
        const cidrTest = cidrRegex({ exact: true }).test(
            editIpRange.IPv4.trim()
        );
        if (!cidrTest) return "Invalid cidr";
        return null;
    };
    const validateName = (): string | null => {
        if (editIpRange.Name.trim().length < 1) return "Name cannot be blank";
        return null;
    };

    const validate = (): boolean => {
        setErrors({});
        const newErrors = {
            IPv4: validateIPv4(),
            Name: validateName(),
        };

        if (newErrors.Name || newErrors.IPv4) {
            setErrors(newErrors);
        }
        return Boolean(newErrors.Name || newErrors.IPv4);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.name === "IPv4") {
            if (errors?.IPv4) {
                setErrors({ ...errors, IPv4: undefined });
            }
            const IPv4 = validateIPv4();
            if (IPv4) {
                setErrors({ ...errors, IPv4 });
            }
        }
        if (e.target.name === "Name") {
            if (errors.Name) {
                setErrors({ ...errors, Name: undefined });
            }
            const Name = validateName();
            if (Name) {
                setErrors({ ...errors, Name });
            }
        }
    };
    return (
        <TableRow style={{backgroundColor}}>
            <TableCell align="center">{editIpRange.IPRangeID}</TableCell>
            <TableCell align="center">
                <TextField
                    name="Name"
                    onChange={handleChange}
                    value={editIpRange.Name}
                    variant="outlined"
                    color="primary"
                    onBlur={handleBlur}
                    error={!!errors.Name}
                    helperText={!!errors.Name && errors.Name}
                    margin="dense"
                    size="small"
                    className="form-control"
                    label="Name"
                />
            </TableCell>
            <TableCell align="center">
                <TextField
                    name="IPv4"
                    multiline
                    onChange={handleChange}
                    value={editIpRange.IPv4}
                    onBlur={handleBlur}
                    error={!!errors.IPv4}
                    helperText={!!errors.IPv4 && errors.IPv4}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className="form-control"
                    label="IP Range"
                />
            </TableCell>
            <TableCell align="center">
                <FormControl
                    size="small"
                    margin="dense"
                    color="primary"
                    variant="outlined"
                    className="form-control"
                >
                    <InputLabel id="type-label">Type</InputLabel>

                    <Select
                        labelId="type-label"
                        id="type"
                        name="Type"
                        value={editIpRange.Type}
                        onChange={(e) =>
                            setEditIpRange({
                                ...editIpRange,
                                Type: e.target.value as
                                    | "multicast"
                                    | "unicastWireless"
                                    | "unicastRemote",
                            })
                        }
                        label="Type"
                    >
                        <MenuItem value="unicastWireless">
                            Unicast Wireless
                        </MenuItem>
                        <MenuItem value="unicastRemote">
                            Unicast Remote
                        </MenuItem>
                        <MenuItem value="multicast">Multicast</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="right">
                <Tooltip title="Save">
                    <IconButton
                        size="small"
                        style={{ marginRight: 12 }}
                        onClick={handleSubmitEdit}
                    >
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton size="small" onClick={() => cancel()}>
                        <UndoIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default IpRangeEdit;
