import React from "react";
import { ILink } from "../../../Core/types";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";

interface LinksEditProps {
    backgroundColor: string;
    link: ILink;
    submit: (link: ILink) => void;
    cancel: () => void;
}

const LinksEdit: React.FC<LinksEditProps> = ({
    link,
    submit,
    cancel,
    backgroundColor,
}): React.ReactElement => {
    const [editLink, setEditLink] = React.useState<ILink>(link);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditLink({ ...editLink, [e.target.name]: e.target.value });
    };

    return (
        <TableRow style={{ backgroundColor }}>
            <TableCell>{editLink.LinkID}</TableCell>
            <TableCell align="center">
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control"
                    onChange={handleChange}
                    name="Name"
                    type="text"
                    variant="outlined"
                    label="Name"
                    value={editLink.Name}
                />
            </TableCell>

            <TableCell align="center">
                <TextField
                    margin="dense"
                    size="small"
                    className="form-control"
                    onChange={handleChange}
                    name="URL"
                    type="text"
                    variant="outlined"
                    label="URL"
                    value={editLink.URL}
                />
            </TableCell>

            <TableCell align="right">
                <IconButton onClick={() => submit(editLink)}>
                    <SaveIcon />
                </IconButton>
                <IconButton onClick={() => cancel()}>
                    <UndoIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default LinksEdit;
