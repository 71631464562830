import React from "react";
import { IEvent } from "../Core/types";

import Row from "./Row";
import EditRow from "./EditRow";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";

interface EventTableProps {
    events: IEvent[];
    isEventTable: boolean;
    isReplayTable: boolean;
    isTemplateTable: boolean;
    onDelete: (event: IEvent) => void;
    onDuplicate: (event: IEvent) => void;
    onMarkActive: (event: IEvent) => void;
    onMarkAsTemplate: (event: IEvent) => void;
    onMarkRebroadcast: (event: IEvent) => void;
    onEditSubmit: (event: IEvent) => Promise<{ errors: any } | null>;
}

const EventTable: React.FC<EventTableProps> = (props): React.ReactElement => {
    // display table data
    // the container will be in charge of providing the events filtered
    const [editingEvent, setEditEvent] = React.useState<IEvent | null>(null);

    const handleEditSubmit = (
        event: IEvent,
        cb?: (confirm: boolean) => void
    ) => {
        props.onEditSubmit(event).then((errors) => {
            if (errors) {
                if (cb) {
                    cb(true);
                }
            } else {
                setEditEvent(null);
            }
        });
    };

    const handleCancel = () => {
        setEditEvent(null);
    };

    const handleSelect = (event: IEvent) => {
        setEditEvent(event);
    };

    return (
        <div className="table__wrapper">
            <Table
                className="template-table"
                aria-label="events table"
                size="small"
            >
                <TableHead>
                    <TableRow className="table__head">
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Title</TableCell>
                        <TableCell align="center">Event Date</TableCell>
                        <TableCell align="center">Access</TableCell>
                        <TableCell align="center">Additional Text</TableCell>
                        <TableCell align="center">Options</TableCell>
                        <TableCell align="center">Live</TableCell>
                        <TableCell align="center">Replay</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.events.map((event, index) => {
                        const bools = {
                            isEventTable: props.isEventTable,
                            isReplayTable: props.isReplayTable,
                            isTemplateTable: props.isTemplateTable,
                        };
                        const bg = index % 2 === 0 ? "#f6f5f7" : "inherit";
                        return editingEvent?.EventID === event.EventID ? (
                            <EditRow
                                key={`${event.EventID}_${index}`}
                                {...bools}
                                backgroundColor={bg}
                                event={event}
                                onCancel={handleCancel}
                                onSubmit={handleEditSubmit}
                                markEventAsLive={props.onMarkActive}
                            />
                        ) : (
                            <Row
                                key={`${event.EventID}_${index}`}
                                {...bools}
                                backgroundColor={bg}
                                event={event}
                                duplicateEvent={props.onDuplicate}
                                deleteEvent={props.onDelete}
                                markEventAsLive={props.onMarkActive}
                                markEventAsRebroadcast={props.onMarkRebroadcast}
                                selectEventToEdit={handleSelect}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

export default EventTable;
